import React, {
  useCallback,
  useContext,
  useState,
  createRef,
  useEffect
} from 'react'
import { Context } from '../../states/AuthContextProvider'
import MaterialTable from 'material-table'
import axios from 'axios'
import { apiUrl } from '../../config/environment'

const columns = [
  {
    title: 'Account',
    tooltip: 'Account Name',
    field: 'account'
  },
  {
    title: 'Reg. TCTR',
    tooltip: 'Regular TCTR Percentage Value',
    field: 'regTctr'
  },
  {
    title: 'Reg. KCTR',
    tooltip: 'Regular KCTR Percentage Value',
    field: 'regKctr'
  },
  {
    title: 'Reg. Factor',
    tooltip: 'Regular Factor Value',
    field: 'regFactor'
  },
  {
    title: 'Ins. TCTR',
    tooltip: 'Instant TCTR Percentage Value',
    field: 'insTctr'
  },
  {
    title: 'Ins. KCTR',
    tooltip: 'Instant KCTR Percentage Value',
    field: 'insKctr'
  },
  {
    title: 'Ins. Factor',
    tooltip: 'Instant Factor Value',
    field: 'insFactor'
  }
]

const MainCutoffTable = ({ version }) => {
  const { state, signOut, setIsError, setIsActionLoading } = useContext(Context)
  const httpOptions = {
    headers: {
      Authorization: `Bearer ${state.authToken}`
    }
  }
  const mainTableRef = createRef()
  const [maintableData, setMaintableData] = useState([])
  const [triggerHook, setTriggerHook] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const onRowAddMain = useCallback(
    async newData => {
      await axios.post(
        `${apiUrl}cut-off/main/create`,
        {
          version,
          main: [
            {
              account: newData.account,
              regular: {
                tctrP: newData.regTctr,
                kctrP: newData.regKctr,
                factor: newData.regFactor
              },
              instant: {
                tctrP: newData.insTctr,
                kctrP: newData.insKctr,
                factor: newData.insFactor
              }
            }
          ]
        },
        httpOptions
      )
      setTriggerHook(!triggerHook)
    },
    // eslint-disable-next-line
    [triggerHook]
  )
  const onRowUpdateMain = useCallback(
    async (newData, oldData) => {
      await axios.post(
        `${apiUrl}cut-off/main/update`,
        {
          version,
          main: [
            {
              account: newData.account,
              regular: {
                tctrP: newData.regTctr,
                kctrP: newData.regKctr,
                factor: newData.regFactor
              },
              instant: {
                tctrP: newData.insTctr,
                kctrP: newData.insKctr,
                factor: newData.insFactor
              }
            }
          ]
        },
        httpOptions
      )
      setTriggerHook(!triggerHook)
    },
    // eslint-disable-next-line
    [triggerHook]
  )
  const onRowDeleteMain = useCallback(
    async oldData => {
      await axios.post(
        `${apiUrl}cut-off/main/delete`,
        {
          version,
          main: [{ account: oldData.account }]
        },
        httpOptions
      )
      setTriggerHook(!triggerHook)
    },
    // eslint-disable-next-line
    [triggerHook]
  )

  useEffect(() => {
    setIsLoading(true)
    async function fetchData () {
      try {
        const response = await axios(
          `${apiUrl}cut-off/main/view/${version}`,
          httpOptions
        )
        const x = []
        response.data.main.map(e =>
          x.push({
            account: e.account,
            regTctr: e.regular.tctrP,
            regKctr: e.regular.kctrP,
            regFactor: e.regular.factor,
            insTctr: e.instant.tctrP,
            insKctr: e.instant.kctrP,
            insFactor: e.instant.factor
          })
        )
        setMaintableData(x)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        if (error.response.status === 403) {
          setIsError('Token Expired! Sign in again.')
          setIsActionLoading(false)
          signOut()
        }
      }
    }
    fetchData()

    // eslint-disable-next-line
  }, [triggerHook])

  return (
    <>
      <MaterialTable
        tableRef={mainTableRef}
        title='Main Table'
        columns={columns}
        data={maintableData}
        editable={{
          onRowAdd: onRowAddMain,
          onRowUpdate: onRowUpdateMain,
          onRowDelete: onRowDeleteMain
        }}
        isLoading={isLoading}
        actions={[
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => setTriggerHook(!triggerHook)
          }
        ]}
      />
    </>
  )
}

export default MainCutoffTable
