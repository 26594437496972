import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import AssignmentIcon from '@material-ui/icons/Assignment'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}))

export const DocListItems = ({ handlePopoverClose, handlePopoverOpen }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <ListItem
        onMouseEnter={e => handlePopoverOpen(e, 'Docs')}
        onMouseLeave={handlePopoverClose}
        button
        onClick={handleClick}
      >
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary='Docs' />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to='/docs/getting-started'
          >
            <ListItem
              button
              className={classes.nested}
              onMouseEnter={e => handlePopoverOpen(e, 'Getting Started')}
              onMouseLeave={handlePopoverClose}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <ListItemText secondary='Getting Started' />
            </ListItem>
          </Link>

          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to='/docs/node'
          >
            <ListItem
              onMouseEnter={e => handlePopoverOpen(e, 'Node')}
              onMouseLeave={handlePopoverClose}
              button
              className={classes.nested}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <ListItemText secondary='Node' />
            </ListItem>
          </Link>

          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to='/docs/subtree'
          >
            <ListItem
              button
              onMouseEnter={e => handlePopoverOpen(e, 'Sub-Tree')}
              onMouseLeave={handlePopoverClose}
              className={classes.nested}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <ListItemText secondary='Sub-Tree' />
            </ListItem>
          </Link>

          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to='/docs/algorithm'
          >
            <ListItem
              onMouseEnter={e => handlePopoverOpen(e, 'Algorithm')}
              onMouseLeave={handlePopoverClose}
              button
              className={classes.nested}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <ListItemText secondary='Algorithm' />
            </ListItem>
          </Link>

          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to='/docs/pre-calculation'
          >
            <ListItem
              onMouseEnter={e => handlePopoverOpen(e, 'Pre Calculation')}
              onMouseLeave={handlePopoverClose}
              button
              className={classes.nested}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <ListItemText secondary='Pre Calculation' />
            </ListItem>
          </Link>

          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to='/docs/post-calculation'
          >
            <ListItem
              onMouseEnter={e => handlePopoverOpen(e, 'Post Calculation')}
              onMouseLeave={handlePopoverClose}
              button
              className={classes.nested}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <ListItemText secondary='Post Calculation' />
            </ListItem>
          </Link>

          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to='/docs/external-dependency'
          >
            <ListItem
              onMouseEnter={e => handlePopoverOpen(e, 'External Dependency')}
              onMouseLeave={handlePopoverClose}
              button
              className={classes.nested}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <ListItemText secondary='External Dependency' />
            </ListItem>
          </Link>

          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to='/docs/testing'
          >
            <ListItem
              onMouseEnter={e => handlePopoverOpen(e, 'Testing')}
              onMouseLeave={handlePopoverClose}
              button
              className={classes.nested}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <ListItemText secondary='Testing' />
            </ListItem>
          </Link>

          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to='/docs/scheduling'
          >
            <ListItem
              onMouseEnter={e => handlePopoverOpen(e, 'Scheduling')}
              onMouseLeave={handlePopoverClose}
              button
              className={classes.nested}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <ListItemText secondary='Scheduling' />
            </ListItem>
          </Link>

        </List>
      </Collapse>
    </>
  )
}
