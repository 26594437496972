import { Tooltip, Typography } from '@material-ui/core'
import React, { useContext, useMemo } from 'react'
import { NodeContext } from '../../states/NodeContextProvider'
import { Outer } from './Outer'

export const NodeInnerCustomReverse = ({ node, config }) => {
  const { nodeState } = useContext(NodeContext)
  const nodeResult = useMemo(
    () => nodeState.nodes.find(e => e.id === node.properties.custom),
    [node, nodeState.nodes]
  )
  return (
    <Outer>
      <Tooltip
        disableHoverListener={false}
        title={node.properties.custom}
        aria-label='add'
      >
        <Typography variant='h5' style={{ color: 'black' }}>
          {nodeResult ? nodeResult.expression : node.properties.custom}
        </Typography>
      </Tooltip>
    </Outer>
  )
}
