import { Typography, useTheme } from '@material-ui/core'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Tour from 'reactour'

const SiteTour = withRouter(
  ({ isTourOpen, setIsTourOpen, location: { pathname }, history }) => {
    const theme = useTheme()
    const steps = [
      {
        selector: '.dash-card',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Let's begin our journey!
            </Typography>
            <Typography variant='body1' gutterBottom>
              This will guide you towards the different parts of the
              application.
            </Typography>
          </>
        ),
        position: 'center'
      },
      {
        selector: '.getting-started',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              View Documentations
            </Typography>
            <Typography variant='body1' gutterBottom>
              This will take us to the Getting Started Guide.
            </Typography>
          </>
        )
      },
      {
        action: () => {
          if (pathname === '/dashboard') {
            history.push('/docs/getting-started')
          }
        },
        selector: 'body',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Getting Started Guide
            </Typography>
            <Typography variant='body1' gutterBottom>
              This guide explains the complex terminologies in a step by step
              follow through manner.
            </Typography>
          </>
        )
      },
      {
        action: () => {
          if (pathname === '/docs/getting-started') {
            history.push('/node-library')
          }
        },
        selector: 'body',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Node Library
            </Typography>
            <Typography variant='body1' gutterBottom>
              Nodes are the first building blocks of an algorithm. we can create
              create, update, delete the nodes here.
            </Typography>
          </>
        )
      },
      {
        selector: '.avail',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Available Parameters
            </Typography>
            <Typography variant='body1' gutterBottom>
              Here we can find a list of available parameters to use in the node
              expressions.
            </Typography>
          </>
        )
      },
      {
        action: () => {
          if (pathname === '/node-library') {
            history.push('/subtree-library')
          }
        },
        selector: 'body',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Sub-Tree Library (Optional)
            </Typography>
            <Typography variant='body1' gutterBottom>
              Subtrees can be created here if needed, but if our algorithm has
              no sub-trees then we can move on Algorithm Library for the next
              step.
            </Typography>
          </>
        )
      },
      {
        selector: '.sub-input',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Enter ID & Description
            </Typography>
            <Typography variant='body1' gutterBottom>
              To create a subtree, we need to first choose a unique ID &
              relevant description.
            </Typography>
          </>
        )
      },
      {
        selector: '.sub-drop',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Drag and Drop Items
            </Typography>
            <Typography variant='body1' gutterBottom>
              This shows us a list of available nodes that we have created
              previously, we can drag and drop them onto the left gray area.
            </Typography>
          </>
        )
      },
      {
        selector: '.sub-add',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Create Sub-Tree
            </Typography>
            <Typography variant='body1' gutterBottom>
              Finally we can press "ADD" create a sub-tree.
            </Typography>
          </>
        )
      },
      {
        selector: '.sub-paper',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              List of Sub-Trees
            </Typography>
            <Typography variant='body1' gutterBottom>
              This is will show a list of created sub-trees.
            </Typography>
          </>
        )
      },
      {
        action: () => {
          if (pathname === '/subtree-library') {
            history.push('/algorithm-library')
          }
        },
        selector: 'body',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Algorithm Library
            </Typography>
            <Typography variant='body1' gutterBottom>
              Once we have some Nodes & Sub-Trees, we can create algorithms in
              the Algorithm Library, which is very similar to how we create
              Sub-Trees.
            </Typography>
          </>
        )
      },
      {
        selector: '.algo-input',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Enter ID & Description
            </Typography>
            <Typography variant='body1' gutterBottom>
              To create an algorithm, we need to first choose a unique ID &
              relevant description.
            </Typography>
          </>
        )
      },
      {
        selector: '.collap-item',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Expand/Collapse
            </Typography>
            <Typography variant='body1' gutterBottom>
              We can click to extend/collapse any of these items.
            </Typography>
          </>
        )
      },
      {
        selector: '.algo-drop',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Drag and Drop Items
            </Typography>
            <Typography variant='body1' gutterBottom>
              This shows us a list of available nodes that we have created
              previously, we can drag and drop them onto the left gray area.
            </Typography>
          </>
        )
      },
      {
        selector: '.algo-add',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Create Algorithm
            </Typography>
            <Typography variant='body1' gutterBottom>
              Finally we can press "ADD" create an Algorithm.
            </Typography>
          </>
        )
      },
      {
        selector: '.algo-paper',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              List of Algorithms
            </Typography>
            <Typography variant='body1' gutterBottom>
              This is will show a list of created Algorithms.
            </Typography>
          </>
        )
      },
      {
        action: () => {
          if (pathname === '/algorithm-library') {
            history.push('/pre-calculation')
          }
        },
        selector: 'body',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Pre Calculation
            </Typography>
            <Typography variant='body1' gutterBottom>
              Once we are done with creating algorithms, Pre-calculation will
              let us add, modify and delete parameters that will go inside
              algorithms.
            </Typography>
          </>
        )
      },
      {
        selector: '.pre-select',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Select Algorithm
            </Typography>
            <Typography variant='body1' gutterBottom>
              We can select the algorithm from the dropdown list, then modify
              the list from the table below.
            </Typography>
          </>
        )
      },
      {
        action: () => {
          if (pathname === '/pre-calculation') {
            history.push('/post-calculation')
          }
        },
        selector: 'body',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Post Calculation
            </Typography>
            <Typography variant='body1' gutterBottom>
              We can also attach the post calculations in a similar way, they
              modify the output returned from the algorithm.
            </Typography>
          </>
        )
      },
      {
        selector: '.test-sidebar',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Testing
            </Typography>
            <Typography variant='body1' gutterBottom>
              We can test nodes, sub-treess and algorithms from here.
            </Typography>
          </>
        )
      },
      {
        action: () => {
          if (pathname === '/post-calculation') {
            history.push('/test-algorithm')
          }
        },
        selector: '.test-sidebar',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Testing Algorithms
            </Typography>
            <Typography variant='body1' gutterBottom>
              first we select the algorithm we want to test from this dropdown.
            </Typography>
          </>
        )
      },
      {
        action: () => {
          if (pathname === '/post-calculation') {
            history.push('/test-algorithm')
          }
        },
        selector: '.select-test-algo',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Select Algorithm
            </Typography>
            <Typography variant='body1' gutterBottom>
              first we select the algorithm we want to test from this dropdown.
            </Typography>
          </>
        )
      },
      {
        selector: '.json-input',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Multiple Inputs
            </Typography>
            <Typography variant='body1' gutterBottom>
              We can write multiple sets of input within this array as a json
              format.
            </Typography>
          </>
        )
      },
      {
        selector: '.save-mock',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Save Input & Import/Export CSV
            </Typography>
            <Typography variant='body1' gutterBottom>
              We can also save the inputs by pressing "SAVE INPUT". Also we can
              choose to export it as csv or import it back.
            </Typography>
          </>
        )
      },
      {
        selector: '.save-mock-list',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              List of Saved Items
            </Typography>
            <Typography variant='body1' gutterBottom>
              By clicking items that will appear here, we can re-use the saved
              items to test our algorithms.
            </Typography>
          </>
        )
      },
      {
        selector: 'body',
        content: () => (
          <>
            <Typography variant='h5' gutterBottom>
              Congratulations!
            </Typography>
            <Typography variant='body1' gutterBottom>
              We have successfully visited most of the parts in our
              applications. To learn more we can go to the "Docs" section in the
              left sidebar. Thanks!
            </Typography>
          </>
        )
      }
    ].map(e => {
      return {
        ...e,
        style: {
          backgroundColor: theme.palette.background.default
        }
      }
    })
    return (
      <>
        <Tour
          steps={steps}
          isOpen={isTourOpen}
          onRequestClose={() => setIsTourOpen(false)}
          rounded={3}
          update={pathname}
          getCurrentStep={curr => console.log(`The current step is ${curr}`)}
          disableInteraction={true}
          disableDotsNavigation={true}
        />
      </>
    )
  }
)

export default SiteTour
