import React, {
  createContext,
  useReducer,
  useContext,
  useState,
  useCallback
} from 'react'
import ExternalReducer, { EXTERNAL_TYPES } from './reducers/externalReducer'
import { Context } from './AuthContextProvider'
import { apiUrl } from '../config/environment'
import Axios from 'axios'

const initialState = {
  externals: [],
  selectedExternal: { id: '' }
}

const ExternalContextProvider = ({ children }) => {
  const [externalState, dispatch] = useReducer(ExternalReducer, initialState)
  const {
    state,
    signOut,
    setIsError: setAuthError,
    setIsActionLoading: setAuthActionLoading
  } = useContext(Context)
  const [isLoading, setIsLoading] = useState(true)
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const httpOptions = {
    headers: {
      Authorization: `Bearer ${state.authToken}`
    }
  }

  const handleError = useCallback(error => {
    if (error.response) {
      if (error.response.status === 403) {
        setAuthError('Token Expired! Sign in again.')
        setAuthActionLoading(false)
        signOut()
      }
      setIsError(error.response.data.msg)
    } else if (error.request) {
      setIsError('Something went wrong while trying to contact the server!')
    } else {
      setIsError(error.toString())
    }
    setIsActionLoading(false)
    // eslint-disable-next-line
  }, [])

  const fetchExternals = useCallback(
    async () => {
      try {
        const res = await Axios(`${apiUrl}external-library/view`, httpOptions)
        const payload = res.data.data
        dispatch({ type: EXTERNAL_TYPES.FETCH_EXTERNALS, payload })
        setIsLoading(false)
      } catch (error) {
        handleError(error)
      }
    }, // eslint-disable-next-line
    [httpOptions]
  )

  const createExternal = useCallback(
    async payload => {
      try {
        setIsActionLoading(true)
        await Axios.post(
          `${apiUrl}external-library/create`,
          payload,
          httpOptions
        )
        dispatch({ type: EXTERNAL_TYPES.CREATE_EXTERNAL, payload })
        setIsActionLoading(false)
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    [httpOptions]
  )

  const editExternal = useCallback(
    async payload => {
      try {
        setIsActionLoading(true)
        const response = await Axios.post(
          `${apiUrl}external-library/update`,
          payload,
          httpOptions
        )
        const statePayload = response.data.data
        dispatch({ type: EXTERNAL_TYPES.EDIT_EXTERNAL, payload: statePayload })
        setIsActionLoading(false)
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    [httpOptions]
  )

  const deleteExternal = useCallback(
    async payload => {
      try {
        setIsActionLoading(true)
        await Axios.post(
          `${apiUrl}external-library/delete`,
          payload,
          httpOptions
        )
        dispatch({ type: EXTERNAL_TYPES.DELETE_EXTERNAL, payload })
        setIsActionLoading(false)
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    [httpOptions]
  )

  const selectExternal = useCallback(payload => {
    dispatch({ type: EXTERNAL_TYPES.SELECT_EXTERNAL, payload })
  }, [])

  return (
    <ExternalContext.Provider
      value={{
        externalState,
        fetchExternals,
        createExternal,
        editExternal,
        deleteExternal,
        isActionLoading,
        setIsActionLoading,
        selectExternal,
        isError,
        setIsError,
        isLoading
      }}
    >
      {children}
    </ExternalContext.Provider>
  )
}

export const ExternalContext = createContext(initialState)

export default ExternalContextProvider
