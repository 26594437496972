import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'
import Axios from 'axios'
import MaterialTable from 'material-table'
import { apiUrl } from '../config/environment'
import { AlgorithmContext } from '../states/AlgorithmContextProvider'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Context } from '../states/AuthContextProvider'
const useStyles = makeStyles(theme => ({
  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const columns = [
  {
    title: 'ID',
    tooltip: 'Node ID',
    field: 'id',
    editable: 'never'
  },
  {
    title: 'Modify Day Start',
    tooltip: '+/- Day Parting Start/End for peak/off-peak',
    field: 'fromStart',
    editable: 'always',
    type: 'numeric',
    validate: rowData => {
      return rowData.fromStart >= 24 || rowData.fromStart < 0
        ? 'exceeds max day hour limit'
        : true
    }
  },
  {
    title: 'Modify Day End',
    tooltip: '+/- Day Parting Start/End for peak/off-peak',
    field: 'fromEnd',
    editable: 'always',
    type: 'numeric',
    validate: rowData => {
      return rowData.fromEnd >= 24 || rowData.fromEnd < 0
        ? 'exceeds max day hour limit'
        : true
    }

  },
  {
    title: 'Interval',
    tooltip: 'Interval',
    field: 'interval',
    editable: 'always',
    type: 'numeric',
    validate: rowData => {
      return rowData.interval < 0 || rowData.interval > 23
        ? 'interval cannot be less than 0 hours or more than 23 hours'
        : true
    }
  },
  {
    title: 'Active',
    tooltip: 'Active In Brain',
    field: 'activeInBrain',
    editable: 'always',
    type: 'boolean'
  },
  {
    title: 'Off-Peak',
    tooltip: 'Off-Peak Algorithm',
    field: 'offPeak',
    editable: 'always',
    type: 'boolean'
  }
]

const prepareTableData = (schedules, algorithms) => {
  const data = []
  for (const e of algorithms) {
    const schedule = schedules.find(sch => sch.id === e.id)
    if (schedule) {
      data.push({
        id: e.id,
        ...schedule
      })
    } else {
      data.push({
        id: e.id,
        fromStart: 0,
        fromEnd: 0,
        interval: 0,
        lastHour: 0,
        lastDay: 0,
        activeInBrain: false,
        offPeak: false,
        runOnce: false
      })
    }
  }
  return data
}

const Scheduling = () => {
  const classes = useStyles()
  const { algorithmState } = useContext(AlgorithmContext)
  const { state } = useContext(Context)
  const httpOptions = {
    headers: {
      Authorization: `Bearer ${state.authToken}`
    }
  }
  const [tableData, setTableData] = useState([])
  const [isErr, setIsErr] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const fetchAllSchedule = useCallback(
    async () => {
      setIsLoading(true)
      try {
        const response = await Axios.get(
          `${apiUrl}schedule/view`,
          httpOptions
        )
        const { data } = response.data
        const preparedData = prepareTableData(data, algorithmState.algorithms)
        setTableData(preparedData)
      } catch (error) {
        setIsErr(error.toString())
      }
      setIsLoading(false)
    },
    // eslint-disable-next-line
    [algorithmState]
  )

  const onRowUpdate = useCallback(
    async (newData, oldData) => {
      setIsLoading(true)
      try {
        const response = await Axios.post(
          `${apiUrl}schedule/update`,
          { algoID: newData.id, updatedSchedule: newData },
          httpOptions
        )
        const { data } = response.data
        const dataUpdate = [...tableData]
        const index = oldData.tableData.id
        dataUpdate[index] = data
        setTableData([...dataUpdate])
      } catch (error) {
        setIsErr(error.toString())
      }
      setIsLoading(false)
    },
    // eslint-disable-next-line
    [tableData]
  )

  useEffect(() => {
    const fetchData = async () => {
      await fetchAllSchedule()
    }
    fetchData()
  }, [fetchAllSchedule])

  return (
    <>
      {isErr && (
        <div className={classes.alert}>
          <Alert severity='error'>
            <AlertTitle>Error</AlertTitle>
            <strong>{isErr}</strong>
          </Alert>
        </div>
      )}
      <MaterialTable
        title='Scheduling'
        columns={columns}
        data={tableData}
        editable={{
          onRowUpdate: onRowUpdate
        }}
        options={{
          actionsColumnIndex: -1,
          paging: true,
          pageSize: 10,
          emptyRowsWhenPaging: true,
          pageSizeOptions: [5, 10, 20, 50],
          sorting: true,
          addRowPosition: 'first',
          grouping: true
        }}
      />
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  )
}

export default Scheduling
