export const EXTERNAL_TYPES = {
  FETCH_EXTERNALS: 'FETCH_EXTERNALS',
  CREATE_EXTERNAL: 'CREATE_EXTERNAL',
  EDIT_EXTERNAL: 'EDIT_EXTERNAL',
  DELETE_EXTERNAL: 'DELETE_EXTERNAL',
  SELECT_EXTERNAL: 'SELECT_EXTERNAL'
}

const ExternalReducer = (state, action) => {
  switch (action.type) {
    case EXTERNAL_TYPES.FETCH_EXTERNALS:
      return {
        ...state,
        externals: action.payload
      }
    case EXTERNAL_TYPES.CREATE_EXTERNAL:
      return {
        ...state,
        externals: [...state.externals, action.payload]
      }
    case EXTERNAL_TYPES.EDIT_EXTERNAL:
      return {
        ...state,
        externals: [
          ...state.externals.filter(e => e.id !== action.payload.id),
          action.payload
        ]
      }
    case EXTERNAL_TYPES.DELETE_EXTERNAL:
      return {
        ...state,
        externals: [
          ...state.externals.filter(e => e.id !== action.payload.id)
        ]
      }
    case EXTERNAL_TYPES.SELECT_EXTERNAL:
      return {
        ...state,
        selectedExternal: action.payload
      }
    default:
      return state
  }
}

export default ExternalReducer
