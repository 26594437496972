import React, { useState } from 'react'
import AuthContextProvider from './states/AuthContextProvider'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Auth from './pages/Auth'
import Layout from './components/Layout'
import ProctectedRoute from './guard/ProtectedRoute'
import NodeContextProvider from './states/NodeContextProvider'
import SubtreeContextProvider from './states/SubtreeContextProvider'
import AlgorithmContextProvider from './states/AlgorithmContextProvider'
import ExternalContextProvider from './states/ExternalContextProvider'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'

const light = {
  palette: {
    type: 'light'
  }
}

const dark = {
  palette: {
    type: 'dark',
    background: {
      default: '#191919',
      paper: '#292929'
    }
  }
}

function App () {
  const [theme, setTheme] = useState(true)
  const appliedTheme = createMuiTheme(theme ? light : dark)
  return (
    <MuiThemeProvider theme={appliedTheme}>
      <Router>
        <AuthContextProvider>
          <Switch>
            <Route exact path='/' component={Auth} />
            <ProctectedRoute path='*'>
              <NodeContextProvider>
                <SubtreeContextProvider>
                  <AlgorithmContextProvider>
                    <ExternalContextProvider>
                      <Layout theme={theme} setTheme={setTheme} />
                    </ExternalContextProvider>
                  </AlgorithmContextProvider>
                </SubtreeContextProvider>
              </NodeContextProvider>
            </ProctectedRoute>
          </Switch>
        </AuthContextProvider>
      </Router>
    </MuiThemeProvider>
  )
}

export default App
