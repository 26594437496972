import React, { useContext } from 'react'
import { NodeContext } from '../../states/NodeContextProvider'
import { REACT_FLOW_CHART } from '@mrblenny/react-flow-chart'
import { Outer } from './Outer'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from '@material-ui/core'
import DragHandleIcon from '@material-ui/icons/DragHandle'

const TooltipTitle = ({ children }) => {
  return (
    <Typography variant='subtitle1' gutterBottom>
      {children}
    </Typography>
  )
}

export const SidebarItem = ({ type, ports, properties, setChangeTrigger }) => {
  const [oClass, setoClass] = React.useState({
    overflow: 'auto',
    border: 'dashed 1px gray',
    width: '100%',
    padding: '5px'
  })
  const { nodeState } = useContext(NodeContext)
  const nodeResult = React.useMemo(
    () => nodeState.nodes.find(e => e.id === properties.custom),
    [properties, nodeState.nodes]
  )

  return (
    <>
      <Outer
        style={oClass}
        draggable='true'
        onDragStart={event => {
          event.dataTransfer.setData(
            REACT_FLOW_CHART,
            JSON.stringify({ type, ports, properties })
          )
          setoClass({
            overflow: 'hidden',
            background: '#4dabf5',
            width: '100%'
          })
        }}
        onDragEnd={event => {
          setoClass({
            overflow: 'auto',
            border: 'dashed 1px gray',
            width: '100%'
          })
          setChangeTrigger(prevState => !prevState)
        }}
      >
        <Tooltip
          disableHoverListener={false}
          title={
            nodeResult ? (
              <TooltipTitle>{nodeResult.expression}</TooltipTitle>
            ) : (
              <TooltipTitle>{properties.custom}</TooltipTitle>
            )
          }
          aria-label='add'
        >
          <ListItem>
            <ListItemIcon>
              <DragHandleIcon fontSize='medium' />
            </ListItemIcon>
            <ListItemText disableTypography primary={properties.custom} />
          </ListItem>
        </Tooltip>
      </Outer>
    </>
  )
}
