import React, { useContext, useState, useCallback } from 'react'
import {
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Grid,
  makeStyles,
  Box,
  Switch,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  Chip
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { paramListDefault } from '../misc/paramList'
import { NodeContext } from '../states/NodeContextProvider'
import { SubTreeContext } from '../states/SubtreeContextProvider'
import { algorithmEval } from '../misc/algorithmEval'
import { Alert, AlertTitle } from '@material-ui/lab'
import { AlgorithmContext } from '../states/AlgorithmContextProvider'
import JSONPretty from 'react-json-pretty'
import * as JSONPrettyMon from 'react-json-pretty/dist/monikai'
import JSONInput from 'react-json-editor-ajrm/index'
import themes from 'react-json-editor-ajrm/themes'
import locale from 'react-json-editor-ajrm/locale/en'
import SaveMock from '../components/SaveMock'
import JsonAsForm from '../components/fragments/JsonAsForm'
import { Link } from 'react-router-dom'
import { ExternalContext } from '../states/ExternalContextProvider'
import { CSVLink } from 'react-csv'
import cloneDeep from 'lodash/cloneDeep'
import { expandConfiguration } from '../misc/expandConfiguration'
import {
  Content,
  NodeInnerCustom,
  Page,
  PortCustom
} from '../components/flowchart'
import { FlowChartWithState } from '@mrblenny/react-flow-chart'
import { data } from '../misc/exampleTreeData'
import TraversalDetail from '../components/TraversalDetail'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    textAlign: 'left'
  },
  root2: {
    flexGrow: 1,
    textAlign: 'left',
    marginBottom: theme.spacing(2)
  },
  formJSON: {
    textAlign: 'left',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  back: {
    background: 'linear-gradient(to right, #00b4db, #0083b0)',
    marginLeft: 0,
    marginRight: 0,
    color: 'white'
  },
  box: {
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 0,
    paddingRight: 0
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const TestAlgorithm = ({ setTitle }) => {
  setTitle('Test Algorithm')
  const classes = useStyles()
  const { nodeState } = useContext(NodeContext)
  const { subtreeState } = useContext(SubTreeContext)
  const { algorithmState } = useContext(AlgorithmContext)
  const { handleSubmit, control } = useForm()
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState({ test: 'press test!' })
  const [isError, setIsError] = useState(null)
  const [jsonInput, setJsonInput] = useState([paramListDefault])
  const [switchState, setSwitchState] = useState(false)
  const { externalState } = useContext(ExternalContext)
  const [algoOut, setAlgoOut] = useState([])
  const [algoOutForExport, setAlgoOutForExport] = useState([])
  const [traView, setTraView] = useState(true)
  const [canvasKey, setCanvasKey] = useState(1)
  const [allTraversal, setAllTraversal] = useState([cloneDeep(data)])
  const [open, setOpen] = useState(false)
  const [dialogData, setDialogData] = useState({
    conf: cloneDeep(data),
    index: 1,
    expanded: cloneDeep(data)
  })
  const allExternals = externalState.externals.map(e => e.id)

  const handleClose = event => {
    setOpen(false)
  }
  const handleOpen = ({ conf, index }) => {
    setDialogData({ ...dialogData, conf, index })
    setOpen(true)
  }

  const prepareTraversalView = useCallback(
    (evaluated, algorithm) => {
      const expanded = expandConfiguration(
        algorithm.conf,
        subtreeState,
        algorithmState,
        nodeState
      )
      if (!expanded.error) {
        setDialogData({
          ...dialogData,
          expanded: {
            ...dialogData.expanded,
            nodes: expanded.nodes,
            links: expanded.links
          }
        })
      }
      const traversedIDsAllArr = []
      evaluated.forEach(e => {
        const temp = []
        for (let i = 0; i < e.display_message.length; i += 2) {
          temp.push(e.display_message[i])
        }
        traversedIDsAllArr.push(temp)
      })

      const traversalDisplayArr = []

      traversedIDsAllArr.forEach(e => {
        const excp = cloneDeep(expanded)
        const nodeTrimmed = {}
        const linkTrimmed = {}
        Object.values(excp.nodes).forEach(node => {
          const bool = e.find(t => t === node.properties.custom)
          if (bool) {
            nodeTrimmed[node.id] = node
          }
        })
        Object.values(nodeTrimmed).forEach(node => {
          Object.values(excp.links).forEach(link => {
            const found = Object.values(nodeTrimmed).find(
              tr => tr.id === link.from.nodeId
            )
            if (found) {
              if (link.to.nodeId === node.id) {
                linkTrimmed[link.id] = link
              }
            }
          })
        })
        excp.nodes = nodeTrimmed
        excp.links = linkTrimmed
        traversalDisplayArr.push({
          offset: {
            x: 0,
            y: 0
          },
          scale: 0.09,
          nodes: {},
          links: {},
          selected: {},
          hovered: {},
          ...excp
        })
      })
      setAllTraversal(traversalDisplayArr)
      setCanvasKey(canvasKey + 1)
    },
    [canvasKey, algorithmState, nodeState, subtreeState, dialogData]
  )

  const onSubmit = useCallback(
    async data => {
      setLoading(true)
      setIsError(null)
      const algorithm = algorithmState.algorithms.find(
        e => e.id === data.algorithmID
      )
      try {
        const copiedData = cloneDeep(jsonInput)
        const evaluatedSub = await algorithmEval(
          algorithm,
          nodeState.nodes,
          subtreeState.subtrees,
          copiedData,
          algorithmState.algorithms,
          allExternals
        )
        setResult(evaluatedSub.value)
        if (evaluatedSub.error.length > 0) {
          setIsError(evaluatedSub.error)
        }
        const algoOutWithIn = []
        const algoInWithChangedOut = []
        jsonInput.forEach((e, i) => {
          algoOutWithIn[i] = {}
          algoInWithChangedOut[i] = {}
          Object.keys(e).forEach(obKey => {
            algoOutWithIn[i][`in_${obKey}`] = e[obKey]
            algoInWithChangedOut[i][obKey] = e[obKey]
          })
        })

        copiedData.forEach((e, i) => {
          Object.keys(e).forEach(obKey => {
            if (obKey === 'cutoff') {
              algoOutWithIn[i][`out_${obKey}`] = Object.values(e[obKey])
              algoInWithChangedOut[i][`out_${obKey}`] = Object.values(e[obKey])
            } else {
              algoOutWithIn[i][`out_${obKey}`] = e[obKey]
              let prePostMatch = false
              for (const node of Object.values(algorithm.conf.nodes)) {
                for (const stateAlgo of algorithmState.algorithms) {
                  if (stateAlgo.id === node.properties.custom) {
                    for (const prePost of stateAlgo.preCal.concat(
                      stateAlgo.postCal
                    )) {
                      if (prePost.expression.includes(`"${obKey}"`)) {
                        prePostMatch = true
                        break
                      }
                    }
                    break
                  }
                }
              }
              if (obKey === 'bid_rate' || obKey === 'bid' || prePostMatch) {
                algoInWithChangedOut[i][`out_${obKey}`] = e[obKey]
              }
            }
          })
        })
        setAlgoOutForExport(algoInWithChangedOut)
        setAlgoOut(algoOutWithIn)
        setLoading(false)
        prepareTraversalView(evaluatedSub.value, algorithm)
      } catch (error) {
        setIsError(error.toString())
        setLoading(false)
      }
    },
    [
      algorithmState.algorithms,
      subtreeState.subtrees,
      nodeState.nodes,
      jsonInput,
      allExternals,
      prepareTraversalView
    ]
  )

  return (
    <>
      <Alert severity='info' style={{ marginBottom: '15px' }}>
        We can test algorithms, import/export csv for test files and it will
        give mock responses with traversal as `display_message`
        <Link style={{ textDecoration: 'none' }} to='/docs/testing'>
          <strong> Learn more </strong>
        </Link>
      </Alert>

      <SaveMock
        mockData={jsonInput}
        setMockData={setJsonInput}
        setSwitchState={setSwitchState}
        setLoading={setLoading}
      />
      {isError && (
        <div className={classes.alert}>
          <Alert severity='error'>
            <AlertTitle>Error</AlertTitle>
            <strong>{isError}</strong>
          </Alert>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} className='json-input'>
            <Paper elevation={3} className={classes.paper}>
              <Box display='flex' flexDirection='row-reverse'>
                <FormControlLabel
                  control={
                    <Switch
                      checked={switchState}
                      onChange={() => setSwitchState(!switchState)}
                      name='useJSON'
                      color='primary'
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label='JSON'
                />
              </Box>
              {switchState ? (
                <JSONInput
                  onChange={e => setJsonInput(JSON.parse(e.json))}
                  id='a_unique_id'
                  placeholder={jsonInput}
                  locale={locale}
                  colors={themes.dark_vscode_tribute}
                  height='100%'
                  width='100%'
                  style={{
                    body: {
                      fontSize: '18px'
                    }
                  }}
                />
              ) : (
                  <JsonAsForm
                    setjson={setJsonInput}
                    json={jsonInput}
                    allTraversal={allTraversal}
                    handleOpen={handleOpen}
                  />
                )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} className={classes.paper}>
              <Box display='flex' flexDirection='row-reverse'>
                <FormControlLabel
                  control={
                    <Switch
                      checked={traView}
                      onChange={() => setTraView(!traView)}
                      name='useJSON'
                      color='primary'
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label='Traversal View'
                />
              </Box>
              <FormControl
                variant='outlined'
                style={{ width: '100%', marginTop: '10px' }}
              >
                <InputLabel id='demo-simple-select-outlined-label'>
                  Algorithm ID
                </InputLabel>
                <Controller
                  className='select-test-algo'
                  as={
                    <Select required>
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      {algorithmState.algorithms &&
                        algorithmState.algorithms.map((e, i) => (
                          <MenuItem key={i} value={e.id}>
                            {e.id}
                          </MenuItem>
                        ))}
                    </Select>
                  }
                  name='algorithmID'
                  rules={{ required: true }}
                  control={control}
                  required
                  defaultValue=''
                />
              </FormControl>
              <Button
                style={{ marginTop: '15px' }}
                fullWidth
                size='large'
                color='primary'
                variant='outlined'
                type='submit'
              >
                TEST
              </Button>
              {!!algoOutForExport.length && (
                <CSVLink
                  filename={'export_all_output.csv'}
                  style={{ textDecoration: 'none', width: '100%' }}
                  data={algoOutForExport}
                  target='_blank'
                >
                  <Button fullWidth variant='outlined'>
                    Export Algo Output
                  </Button>
                </CSVLink>
              )}
              {!traView && (
                <Box
                  overflow='auto'
                  style={{ background: 'black', color: 'white' }}
                >
                  <JSONPretty
                    id='json-pretty'
                    data={result}
                    theme={JSONPrettyMon}
                  ></JSONPretty>
                </Box>
              )}
              {traView &&
                allTraversal.map((e, i) => (
                  <>
                    <Paper variant='outlined' style={{ margin: '8px' }}>
                      {!!algoOut[i] && (
                        <div style={{ margin: '5px' }}>
                          <Chip
                            style={{ marginRight: '5px' }}
                            color='primary'
                            variant='outlined'
                            label={`campaign_id: ${algoOut[i].in_facebook_campaign_id}`}
                          />
                          <Chip
                            color='primary'
                            variant='outlined'
                            label={`bid_rate: ${algoOut[i].out_bid_rate}`}
                          />
                        </div>
                      )}

                      <Page key={i}>
                        <Content style={{ maxHeight: '250px' }}>
                          <FlowChartWithState
                            key={i + canvasKey}
                            initialValue={e}
                            Components={{
                              NodeInner: NodeInnerCustom,
                              Port: PortCustom
                            }}
                            config={{
                              zoom: {
                                maxScale: 0.9,
                                minScale: 0.09
                              },
                              readonly: true
                            }}
                          />
                        </Content>
                      </Page>
                      <Button
                        onClick={ev => handleOpen({ conf: e, index: i })}
                        variant='outlined'
                        color='secondary'
                        fullWidth
                      >
                        Details
                      </Button>
                    </Paper>
                  </>
                ))}
            </Paper>
          </Grid>
        </Grid>
      </form>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <TraversalDetail
        conf={dialogData.conf}
        input={jsonInput[dialogData.index]}
        output={result[dialogData.index]}
        full={dialogData.expanded}
        open={open}
        handleClose={handleClose}
      />
    </>
  )
}

export default TestAlgorithm
