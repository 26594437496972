export const data = {
  offset: {
    x: 0,
    y: 0
  },
  scale: 0.8,
  nodes: {},
  links: {},
  selected: {},
  hovered: {}
}
