import React, {
  createContext,
  useReducer,
  useContext,
  useState,
  useCallback
} from 'react'
import SubtreeReducer, { SUBTREE_TYPES } from './reducers/subtreeReducer'
import { Context } from './AuthContextProvider'
import Axios from 'axios'
import { apiUrl } from '../config/environment'

const initialState = {
  subtrees: []
}

const SubtreeContextProvider = ({ children }) => {
  const [subtreeState, dispatch] = useReducer(SubtreeReducer, initialState)
  const { state, signOut, setIsError : setAuthError, setIsActionLoading: setAuthActionLoading } = useContext(Context)
  const [isLoading, setIsLoading] = useState(true)
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const httpOptions = {
    headers: {
      Authorization: `Bearer ${state.authToken}`
    }
  }

  const handleError = useCallback(error => {
    if (error.response) {
      if(error.response.status === 403){
        setAuthError('Token Expired! Sign in again.')
        setAuthActionLoading(false)
        signOut()
      }
      setIsError(error.response.data.msg)
    } else if (error.request) {
      setIsError('Something went wrong while trying to contact the server!')
    } else {
      setIsError(error.toString())
    }
    setIsActionLoading(false)
     // eslint-disable-next-line
  }, [])

  const fetchSubtrees = useCallback(
    async () => {
      try {
        setIsActionLoading(true)
        const res = await Axios(`${apiUrl}sub-tree/view`, httpOptions)
        const payload = res.data.subTrees
        dispatch({ type: SUBTREE_TYPES.FETCH_SUBTREES, payload })
        setIsActionLoading(false)
        setIsLoading(false)
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    [httpOptions]
  )

  const createSubtree = useCallback(
    async payload => {
      try {
        setIsActionLoading(true)
        await Axios.post(`${apiUrl}sub-tree/create`, payload, httpOptions)
        dispatch({ type: SUBTREE_TYPES.CREATE_SUBTREE, payload })
        setIsActionLoading(false)
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    [httpOptions]
  )

  const editSubtree = useCallback(
    async payload => {
      try {
        setIsActionLoading(true)
        await Axios.post(`${apiUrl}sub-tree/update`, payload, httpOptions)
        dispatch({ type: SUBTREE_TYPES.EDIT_SUBTREE, payload })
        setIsActionLoading(false)
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    [httpOptions]
  )

  const deleteSubtree = useCallback(
    async payload => {
      try {
        setIsActionLoading(true)
        await Axios.post(`${apiUrl}sub-tree/delete`, payload, httpOptions)
        dispatch({ type: SUBTREE_TYPES.DELETE_SUBTREE, payload })
        setIsActionLoading(false)
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    [httpOptions]
  )

  return (
    <SubTreeContext.Provider
      value={{
        subtreeState,
        fetchSubtrees,
        createSubtree,
        editSubtree,
        deleteSubtree,
        isActionLoading,
        isError,
        setIsError,
        isLoading
      }}
    >
      {children}
    </SubTreeContext.Provider>
  )
}

export const SubTreeContext = createContext(initialState)

export default SubtreeContextProvider
