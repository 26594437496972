export const paramList = [
  'adnet_campaign_category_name',
  'facebook_campaign_name',
  'facebook_starting_bid_average',
  'delta_adnet_advertiser_clicks',
  'delta_s1_estimated_advertiser_clicks',
  'facebook_current_bid_average',
  'enet',
  's1rpc',
  'delta_s1rpc',
  'ebrpc',
  'tctr',
  'kctr',
  'fbsc',
  'rpc3h',
  'facebook_cost',
  'facebook_searches',
  'category_search_cost_limit',
  's1_estimated_advertiser_clicks',
  'adnet_advertiser_clicks'
]

export const paramListForNodes = [
  'facebook_current_bid_average',
  'adnet_campaign_category_name',
  'facebook_campaign_name',
  'facebook_starting_bid_average',
  'delta_adnet_advertiser_clicks',
  'delta_s1_estimated_advertiser_clicks',
  'enet',
  's1rpc',
  'delta_s1rpc',
  'ebrpc',
  'tctr',
  'kctr',
  'fbsc',
  'rpc3h',
  'facebook_cost',
  'facebook_searches',
  'category_search_cost_limit',
  's1_estimated_advertiser_clicks',
  'adnet_advertiser_clicks',
  'cutoff.tctrP',
  'cutoff.kctrP',
  'cutoff.factor'
]

export const paramListOnlyPostCal = [
  'bid',
  'pre_leaf_id',
  'leaf_id',
  'display_message'
]

export const paramListDefault = {
  facebook_campaign_id: '1',
  facebook_current_bid_average: '0',
  adnet_campaign_category_name: 'Online Degree - All Devices',
  facebook_campaign_name: 'name',
  facebook_starting_bid_average: '0',
  delta_adnet_advertiser_clicks: '0',
  delta_s1_estimated_advertiser_clicks: '0',
  enet: '0',
  s1rpc: '0',
  delta_s1rpc: '0',
  ebrpc: '0',
  tctr: '0',
  kctr: '0',
  fbsc: '0',
  rpc3h: '0',
  facebook_cost: '0',
  facebook_searches: '0',
  category_search_cost_limit: '0',
  s1_estimated_advertiser_clicks: '0',
  adnet_advertiser_clicks: '0'
}

export const functionList = [
  'min(...args)',
  'max(...args)',
  'avg(...args)',
  'abs(arg)',
  'search("word", param)',
  'currentHour()',
  'currentDay()',
  'between(value, lower_limit, higher_limit)',
  'floor(value)',
  'ceil(value)',
  'round(value)',
  'sign(value)',
  'trunc(value)',
  'diffp(initial_value, final_value)',
  'lookup(param, "dependency_id.column_to_match", "col_to_get_value", "?expression")',
  'zeroify(arg)'
]

export const prePosFuntList = [
  'modify("parameter_name", var)',
  'allow(["parameter_name1", "parameter_name2", ...])',
  'min(...args)',
  'max(...args)',
  'avg(...args)',
  'abs(arg)',
  'search("word", param)',
  'currentHour()',
  'currentDay()',
  'between(value, lower_limit, higher_limit)',
  'floor(value)',
  'ceil(value)',
  'round(value)',
  'sign(value)',
  'trunc(value)',
  'diffp(initial_value, final_value)',
  'zeroify(arg)'
]

export const operatorList = [
  '+',
  '-',
  '*',
  '/',
  '&&',
  '||',
  '()',
  '<',
  '<=',
  '==',
  '>',
  '>=',
  '!=',
  'condition ? exprIfTrue : exprIfFalse'
]
