import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import { Context } from '../states/AuthContextProvider'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { Alert, AlertTitle } from '@material-ui/lab'
import Copyright from '../components/Copyright'
const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  button: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px'
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/weekly?office)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}))

export default function Auth () {
  const classes = useStyles()
  const { signIn, isError, isActionLoading } = useContext(Context)
  const { register, handleSubmit, errors } = useForm()
  const history = useHistory()
  const onSubmit = async data => {
    await signIn(data)
    if (!isError) {
      history.push('dashboard')
    }
  }

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography component='h1' variant='h5'>
            Adnet Brain Management
          </Typography>
          <Avatar className={classes.avatar} variant='square'>
            <LockOutlinedIcon />
          </Avatar>
          {isError && (
              <div className={classes.alert}>
                <Alert severity='error'>
                  <AlertTitle>Error</AlertTitle>
                  <strong>{isError}</strong>
                </Alert>
              </div>
          )}
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              error={!!errors.email}
              autoComplete='email'
              autoFocus
              inputRef={register({ required: true })}
            />
            {errors.email && (
              <Typography variant="subtitle2" color="error">
                Email is required
              </Typography>
            )}

            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              name='password'
              error={!!errors.password}
              label='Password'
              type='password'
              id='password'
              autoComplete='current-password'
              inputRef={register({ required: true })}
            />
            {errors.password && (
              <Typography variant="subtitle2" color="error">
                Password is required
              </Typography>
            )}
            <Box mt={2}>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.button}
                disabled={isActionLoading}
              >
                Sign In
              </Button>
            </Box>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  )
}
