import Axios from 'axios'
import React, { createContext, useCallback, useState } from 'react'
import { createPersistedReducer } from '../hooks/usePersistedReducer'
import AuthReducer, { AUTH_TYPES } from './reducers/authReducer'
const usePersistedReducer = createPersistedReducer('state')

const initialState = {
  username: null,
  authToken: null
}

const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = usePersistedReducer(AuthReducer, initialState)
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const handleError = useCallback(error => {
    if (error.response) {
      if (error.response.status === 401) {
        setIsError('Email or password not correct')
      }
      else {
        setIsError(error.response.data.msg)
      }
    } else if (error.request) {
      setIsError('Something went wrong while trying to contact the server!')
    } else {
      setIsError(error.toString())
    }
    setIsActionLoading(false)
  }, [])

  const signIn = useCallback(
    async payload => {
      try {
        setIsActionLoading(true)
        const result = await Axios.post(
          'https://adnet-id.adnet.vip/auth/login',
          payload
        )

        dispatch({
          type: AUTH_TYPES.SIGN_IN,
          payload: {
            username: payload.email,
            authToken: result.data.data.authToken
          }
        })
        setIsActionLoading(false)
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    [dispatch]
  )

  const signOut = useCallback(
    payload => {
      dispatch({ type: AUTH_TYPES.SIGN_OUT, payload })
    },
    [dispatch]
  )

  return (
    <Context.Provider
      value={{ state, signIn, signOut, isError, setIsError, isActionLoading, setIsActionLoading }}
    >
      {children}
    </Context.Provider>
  )
}

export const Context = createContext(initialState)

export default AuthContextProvider
