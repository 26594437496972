import React, {
  createContext,
  useReducer,
  useContext,
  useState,
  useCallback
} from 'react'
import AlgorithmReducer, { ALGORITHM_TYPES } from './reducers/algorithmReducer'
import { Context } from './AuthContextProvider'
import Axios from 'axios'
import { apiUrl } from '../config/environment'

const initialState = {
  algorithms: [],
  selectedAlgorithm: ''
}

const AlgorithmContextProvider = ({ children }) => {
  const [algorithmState, dispatch] = useReducer(AlgorithmReducer, initialState)
  const { state, signOut, setIsError : setAuthError, setIsActionLoading: setAuthActionLoading } = useContext(Context)
  const [isLoading, setIsLoading] = useState(true)
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const httpOptions = {
    headers: {
      Authorization: `Bearer ${state.authToken}`
    }
  }

  const handleError = useCallback(error => {
    if (error.response) {
      if(error.response.status === 403){
        setAuthError('Token Expired! Sign in again.')
        setAuthActionLoading(false)
        signOut()
      }
      setIsError(error.response.data.msg)
    } else if (error.request) {
      setIsError('Something went wrong while trying to contact the server!')
    } else {
      setIsError(error.toString())
    }
    setIsActionLoading(false)
     // eslint-disable-next-line
  }, [])

  const fetchAlgorithms = useCallback(
    async () => {
      try {
        setIsActionLoading(true)
        const res = await Axios(`${apiUrl}algorithm/view`, httpOptions)
        const payload = res.data.algorithms
        dispatch({ type: ALGORITHM_TYPES.FETCH_ALGORITHMS, payload })
        setIsLoading(false)
        setIsActionLoading(false)
      } catch (error) {
        handleError(error)
      }
    }, // eslint-disable-next-line
    [httpOptions]
  )

  const createAlgorithm = useCallback(
    async payload => {
      try {
        setIsActionLoading(true)
        await Axios.post(`${apiUrl}algorithm/create`, payload, httpOptions)
        dispatch({ type: ALGORITHM_TYPES.CREATE_ALGORITHM, payload })
        setIsActionLoading(false)
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    [httpOptions]
  )

  const editAlgorithm = useCallback(
    async payload => {
      try {
        setIsActionLoading(true)
        await Axios.post(`${apiUrl}algorithm/update`, payload, httpOptions)
        dispatch({ type: ALGORITHM_TYPES.EDIT_ALGORITHM, payload })
        setIsActionLoading(false)
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    [httpOptions]
  )

  const deleteAlgorithm = useCallback(
    async payload => {
      try {
        setIsActionLoading(true)
        await Axios.post(`${apiUrl}algorithm/delete`, payload, httpOptions)
        dispatch({ type: ALGORITHM_TYPES.DELETE_ALGORITHM, payload })
        setIsActionLoading(false)
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    [httpOptions]
  )

  const selectAlgorithm = useCallback(payload => {
    dispatch({ type: ALGORITHM_TYPES.SELECT_ALGORITHM, payload })
  }, [])

  return (
    <AlgorithmContext.Provider
      value={{
        algorithmState,
        fetchAlgorithms,
        createAlgorithm,
        editAlgorithm,
        deleteAlgorithm,
        isActionLoading,
        selectAlgorithm,
        isError,
        setIsError,
        isLoading
      }}
    >
      {children}
    </AlgorithmContext.Provider>
  )
}

export const AlgorithmContext = createContext(initialState)

export default AlgorithmContextProvider
