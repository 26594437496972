import { getDay, getHours } from 'date-fns'
const { utcToZonedTime } = require('date-fns-tz')
export const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const externals = {
  max: function () {
    return Math.max(...arguments)
  },
  min: function () {
    return Math.min(...arguments)
  },
  avg: function () {
    const arr = Object.values(arguments)
    return arr.reduce((acc, e) => acc + e) / arguments.length
  },
  abs: function (x) {
    return Math.abs(x)
  },
  search: function (word, parm) {
    return parm.toLowerCase().includes(word.toLowerCase())
  },
  currentHour: function () {
    const date = new Date()
    const timeZone = 'America/Los_Angeles'
    const zonedDate = utcToZonedTime(date, timeZone)
    return getHours(zonedDate)
  },
  currentDay: function () {
    const date = new Date()
    const timeZone = 'America/Los_Angeles'
    const zonedDate = utcToZonedTime(date, timeZone)
    return days[getDay(zonedDate)]
  },
  between: function (value, lowLim, highLim) {
    return value >= lowLim && value <= highLim
  },
  floor: function (value) {
    return Math.floor(value)
  },
  ceil: function (value) {
    return Math.ceil(value)
  },
  round: function (value) {
    const sign = Math.sign(value)
    const absVal = Math.abs(value)
    const intPart = Math.trunc(absVal)
    const mark = (intPart + 0.45)
    const rounded = absVal > mark ? intPart + 1 : intPart
    return sign * rounded
  },
  trunc: function (value) {
    return Math.trunc(value)
  },
  diffp: function (initial, final) {
    return ((final - initial) / Math.abs(initial)) * 100
  },
  zeroify: function (num) {
    let final = 0
    if (!Number.isFinite(num)) {
      final = 0
    } else {
      final = num || 0
    }
    return final
  }
}
export default externals
