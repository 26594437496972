import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import BugReportIcon from '@material-ui/icons/BugReport'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}))

export const TestListItems = ({ handlePopoverClose, handlePopoverOpen }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <ListItem
        className='test-sidebar'
        button
        onMouseEnter={e => handlePopoverOpen(e, 'Testing')}
        onMouseLeave={handlePopoverClose}
        onClick={handleClick}
      >
        <ListItemIcon>
          <BugReportIcon />
        </ListItemIcon>
        <ListItemText primary='Testing' />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to='/test-node'
          >
            <ListItem
              button
              onMouseEnter={e => handlePopoverOpen(e, 'Test Node')}
              onMouseLeave={handlePopoverClose}
              className={classes.nested}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <ListItemText primary='Node' />
            </ListItem>
          </Link>
          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to='/test-subtree'
          >
            <ListItem
              onMouseEnter={e => handlePopoverOpen(e, 'Test Subtree')}
              onMouseLeave={handlePopoverClose}
              button
              className={classes.nested}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <ListItemText primary='Subtree' />
            </ListItem>
          </Link>

          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to='/test-algorithm'
          >
            <ListItem
              onMouseEnter={e => handlePopoverOpen(e, 'Test Algorithm')}
              onMouseLeave={handlePopoverClose}
              button
              className={classes.nested}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <ListItemText primary='Algorithm' />
            </ListItem>
          </Link>
        </List>
      </Collapse>
    </>
  )
}
