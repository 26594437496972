import React, { useRef, useContext, useState, useEffect } from 'react'

import {
  Content,
  Page,
  Sidebar,
  SidebarItem,
  Message,
  NodeInnerCustom,
  NodeInnerCustomReverse,
  PortCustom
} from '../components/flowchart'
import DeleteIcon from '@material-ui/icons/Delete'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import { data } from '../misc/exampleTreeData'
import { portConf } from '../misc/portConfiguration'

import {
  makeStyles,
  Box,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  ListItem,
  ListItemText,
  FormControlLabel,
  Switch
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { FlowChartWithState } from '@mrblenny/react-flow-chart'
import { SubTreeContext } from '../states/SubtreeContextProvider'
import { NodeContext } from '../states/NodeContextProvider'
import { AlgorithmContext } from '../states/AlgorithmContextProvider'
import { Alert, AlertTitle } from '@material-ui/lab'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import { Link, Prompt } from 'react-router-dom'
import CanvasItems from '../components/fragments/CanvasItems'
import CanvasZoomButtons from '../components/fragments/CanvasZoomButtons'
import AlgoCloneWarning from '../components/fragments/AlgoCloneWarning'
import DeleteDialog from '../components/fragments/DeleteDialog'
import cloneDeep from 'lodash/cloneDeep'
import { expandConfiguration } from '../misc/expandConfiguration'

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  box: {
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 0,
    paddingRight: 0
  },
  back: {
    background: 'linear-gradient(to right, #00b4db, #0083b0)',
    marginLeft: 0,
    marginRight: 0,
    color: 'white'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  chip: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  inList: {
    maxHeight: '350px',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '24px'
  },
  linkStyle: {
    textDecoration: 'none',
    color: 'black'
  },
  setButtons: {
    textTransform: 'none'
  },
  draftBorder: {
    border: 'solid 2px red'
  }
}))

const AlgorithmTree = ({ setTitle }) => {
  setTitle('Algorithm Library')
  const classes = useStyles()
  const flowchartRef = useRef(null)
  const clonedData = cloneDeep(data)
  const [chartData, setChartData] = useState(clonedData)
  const [canvasKey, setCanvasKey] = useState(1)
  const [isEdit, setIsEdit] = useState(false)
  const [isDraft, setIsDraft] = useState(false)
  const [isClone, setIsClone] = useState(false)
  const [nodeCollapsed, setnodeCollapsed] = useState(true)
  const [subCollapsed, setsubCollapsed] = useState(false)
  const [algoCollapsed, setalgoCollapsed] = useState(false)
  const { subtreeState } = useContext(SubTreeContext)
  const { nodeState } = useContext(NodeContext)
  const {
    algorithmState,
    editAlgorithm,
    createAlgorithm,
    deleteAlgorithm,
    isActionLoading,
    isError,
    setIsError
  } = useContext(AlgorithmContext)

  const { handleSubmit, setValue, control, getValues } = useForm()

  const [searchTerm, setSearchTerm] = React.useState('')
  const [searchResults, setSearchResults] = React.useState([])
  const [sortOrder, setSortOrder] = React.useState(true)
  const [isExpression, setIsExpression] = React.useState(false)
  const [cloneWarnOpen, setCloneWarnOpen] = useState(false)
  const [openDel, setOpenDel] = useState(false)
  const [changeTrigger, setChangeTrigger] = useState(false)
  const [loadedConf, setLoadedConf] = useState({})
  const [isExpand, setIsExpand] = useState(false)
  const [preExpand, setPreExpand] = useState(cloneDeep(data))

  const handleChange = (event: any) => {
    setSearchTerm(event.target.value)
  }

  useEffect(() => {
    let results = []
    if (sortOrder) {
      results = nodeState.nodes
        .filter(e =>
          e.id.toLowerCase().includes(searchTerm.trim().toLowerCase())
        )
        .sort((a, b) =>
          a.id
            .trim()
            .toLowerCase()
            .localeCompare(b.id.trim().toLowerCase())
        )
    } else {
      results = nodeState.nodes
        .filter(e =>
          e.id.toLowerCase().includes(searchTerm.trim().toLowerCase())
        )
        .sort((a, b) =>
          a.id
            .trim()
            .toLowerCase()
            .localeCompare(b.id.trim().toLowerCase())
        )
        .reverse()
    }
    setSearchResults(results)
  }, [searchTerm, sortOrder, nodeState.nodes])

  const onSubmit = async data => {
    try {
      const getAlgo = algorithmState.algorithms.find(e => e.id === data.id)
      if (isEdit && !isClone) {
        await editAlgorithm({
          id: data.id,
          description: data.description,
          conf: flowchartRef.current.state,
          preCal: getAlgo.preCal,
          postCal: getAlgo.postCal
        })
        cancelEdit()
      } else if (isEdit && isClone) {
        setCloneWarnOpen(true)
        await createAlgorithm({
          id: data.cloneID,
          description: data.description,
          conf: flowchartRef.current.state,
          preCal: getAlgo.preCal,
          postCal: getAlgo.postCal
        })
        setIsClone(false)
        cancelEdit()
      } else {
        await createAlgorithm({
          id: data.id,
          description: data.description,
          conf: flowchartRef.current.state,
          preCal: [],
          postCal: []
        })
        cancelEdit()
      }
    } catch (error) {
      console.log(error.response.data)
    }
  }

  const refreshCanvas = () => setCanvasKey(canvasKey + 1)

  const handleAlgorithmClick = e => {
    if (isEdit || isDraft) {
      alert('You have unsaved changes, save or discard the progress first.')
    } else {
      const copied = cloneDeep(e.conf)
      const copiedLoad = cloneDeep(e.conf)
      setChartData(copied)
      setLoadedConf(copiedLoad)
      setValue('id', e.id)
      setValue('description', e.description)
      setIsEdit(true)
      refreshCanvas()
    }
  }
  const handleDeleteClick = async () => {
    try {
      await deleteAlgorithm({
        id: getValues('id')
      })
      cancelEdit()
    } catch (error) {
      console.log(error.response.data)
    }
  }

  const cancelEdit = () => {
    const copied = cloneDeep(data)
    setChartData(copied)
    setValue('id', '')
    setValue('description', '')
    setIsEdit(false)
    setIsDraft(false)
    setIsExpand(false)
    refreshCanvas()
  }

  const handleClose = confirmed => {
    setOpenDel(false)
    if (confirmed) {
      handleDeleteClick()
    }
  }

  const handleExpandChange = ev => {
    setIsExpand(!isExpand)
    if (isExpand) {
      setChartData(preExpand)
    } else {
      setPreExpand(cloneDeep(chartData))
      const { nodes, links, error } = expandConfiguration(
        chartData,
        subtreeState,
        algorithmState,
        nodeState
      )
      if (error) {
        setIsError(error)
      } else {
        setChartData({
          ...chartData,
          nodes,
          links
        })
      }
    }
    refreshCanvas()
  }

  useEffect(() => {
    if (isEdit) {
      if (JSON.stringify(loadedConf) !== JSON.stringify(chartData)) {
        setIsDraft(true)
      }
    } else {
      if (JSON.stringify(data) !== JSON.stringify(chartData)) {
        setIsDraft(true)
      }
    }
    // eslint-disable-next-line
  }, [changeTrigger])

  return (
    <>
      <Alert severity='info' style={{ marginBottom: '15px' }}>
        An Algorithm can be consisting of nodes, subtrees and other algorithms,
        pick and drop items from the right side and link them in green/red to
        compose an algorithm.{' '}
        <Link style={{ textDecoration: 'none' }} to='/docs/algorithm'>
          <strong> Learn more </strong>
        </Link>
      </Alert>

      {isDraft && (
        <Alert severity='warning'>
          You are in Draft Mode. Please save or discard any changes.
        </Alert>
      )}

      <Prompt
        when={isEdit || isClone || isDraft}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      {isError && (
        <Alert
          variant='outlined'
          severity='error'
          onClose={() => {
            setIsError(false)
          }}
        >
          <AlertTitle>Error</AlertTitle>
          {isError}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='algo-add'>
          <Box
            display='flex'
            flexDirection='row-reverse'
            p={1}
            m={1}
            className={classes.box}
          >
            {isEdit && (
              <>
                <Button
                  style={{ marginLeft: '5px' }}
                  color='secondary'
                  variant='contained'
                  aria-label='delete'
                  onClick={() => setOpenDel(true)}
                  startIcon={<DeleteIcon />}
                  disableElevation
                >
                  Delete
                </Button>
                <Button
                  style={{ marginLeft: '5px' }}
                  color='primary'
                  variant='outlined'
                  aria-label='add'
                  type='submit'
                  disabled={isExpand}
                  onClick={() => setIsClone(true)}
                >
                  Clone
                </Button>
                <Controller
                  as={
                    <TextField
                      id='outlined-basic'
                      label='Clone ID'
                      variant='outlined'
                      required={isClone}
                      placeholder='Clone Algorithm ID'
                      autoFocus
                    />
                  }
                  name='cloneID'
                  control={control}
                  defaultValue=''
                  style={{ marginLeft: '5px', marginRight: '5px' }}
                />
                <Button
                  style={{ marginLeft: '5px' }}
                  color='secondary'
                  variant='outlined'
                  aria-label='add'
                  onClick={cancelEdit}
                >
                  Cancel Edit
                </Button>
              </>
            )}
            {(isDraft || isEdit) && (
              <Button
                color='primary'
                aria-label='add'
                className={classes.back}
                type='submit'
                disabled={isExpand}
              >
                Save
              </Button>
            )}

            {isDraft && !isEdit && (
              <Button
                style={{ marginRight: '5px' }}
                color='secondary'
                variant='outlined'
                aria-label='add'
                onClick={cancelEdit}
              >
                Discard
              </Button>
            )}
          </Box>
        </div>

        <Paper elevation={1} className='algo-paper'>
          <Box display='flex' p={1} m={1} className={classes.chip}>
            {algorithmState.algorithms.length > 0 ? (
              algorithmState.algorithms.map((e, i) => (
                <Button
                  key={i}
                  variant='outlined'
                  color='primary'
                  className={classes.setButtons}
                  onClick={() => handleAlgorithmClick(e)}
                >
                  {e.id}
                </Button>
              ))
            ) : (
              <p>No Data Found</p>
            )}
          </Box>
        </Paper>

        <Paper elevation={3} className={isDraft ? classes.draftBorder : null}>
          <Page>
            <Content>
              {isExpression ? (
                <FlowChartWithState
                  ref={flowchartRef}
                  key={canvasKey}
                  initialValue={chartData}
                  Components={{
                    NodeInner: NodeInnerCustomReverse,
                    Port: PortCustom
                  }}
                  config={{
                    zoom: {
                      maxScale: 0.9,
                      minScale: 0.1
                    }
                  }}
                />
              ) : (
                <FlowChartWithState
                  ref={flowchartRef}
                  key={canvasKey}
                  initialValue={chartData}
                  Components={{
                    NodeInner: NodeInnerCustom,
                    Port: PortCustom
                  }}
                  config={{
                    zoom: {
                      maxScale: 0.9,
                      minScale: 0.1
                    }
                  }}
                />
              )}
            </Content>
            <Sidebar>
              <div style={{ padding: '5px' }} className='algo-input'>
                <Controller
                  as={
                    <TextField
                      id='outlined-basic'
                      label='ID'
                      variant='outlined'
                      fullWidth
                      required
                      placeholder='Algorithm ID'
                      autoFocus
                      disabled={isEdit}
                    />
                  }
                  name='id'
                  onChange={e => {
                    setIsDraft(true)
                    return e[0].target.value
                  }}
                  control={control}
                  defaultValue=''
                />
                <Controller
                  as={
                    <TextField
                      id='outlined-basic'
                      label='Description'
                      variant='outlined'
                      fullWidth
                      required
                      multiline
                      style={{ marginTop: '5px' }}
                      placeholder='Algorithm Description'
                    />
                  }
                  onChange={e => {
                    setIsDraft(true)
                    return e[0].target.value
                  }}
                  name='description'
                  control={control}
                  defaultValue=''
                />
              </div>

              <Message>
                Drag a node, subtree or algorithm to the left hand side to
                create a decision tree.
              </Message>
              <Box display='flex' flexDirection='row-reverse' p={1} m={1}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isExpression}
                      onChange={() => setIsExpression(!isExpression)}
                      name='sort'
                      color='primary'
                    />
                  }
                  label={<p style={{fontSize:'12px'}}>Expression View</p>}
                  labelPlacement='start'
                />
                <CanvasZoomButtons
                  flowchartRef={flowchartRef}
                  setChartData={setChartData}
                  refreshCanvas={refreshCanvas}
                  chartData={chartData}
                />
              </Box>
              <Box display='flex' flexDirection='row-reverse' p={1} m={1}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isExpand}
                      onChange={handleExpandChange}
                      name='sort'
                      color='primary'
                    />
                  }
                  label={<p style={{ fontSize: '12px' }}>Expand</p>}
                  labelPlacement='start'
                />
              </Box>
              <CanvasItems
                setCollapsed={setnodeCollapsed}
                collapsed={nodeCollapsed}
                icon={<AddCircleOutlineIcon fontSize='small' />}
                title='NODE LIST'
              >
                <List className={classes.inList}>
                  <Box display='flex'>
                    <TextField
                      label='SEARCH NODES'
                      fullWidth
                      variant='outlined'
                      value={searchTerm}
                      onChange={handleChange}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={sortOrder}
                          onChange={() => setSortOrder(!sortOrder)}
                          name='sort'
                          color='primary'
                        />
                      }
                      label='ASC'
                      labelPlacement='start'
                    />
                  </Box>
                  {searchResults.length > 0 ? (
                    searchResults.map((e, i) => (
                      <>
                        <div key={i}>
                          <SidebarItem
                            type='input-output'
                            ports={portConf}
                            properties={{
                              custom: e.id
                            }}
                            setChangeTrigger={setChangeTrigger}
                          />
                        </div>
                      </>
                    ))
                  ) : (
                    <Link className={classes.linkStyle} to='/node-library'>
                      <ListItem button>
                        <ListItemText disableTypography primary='Create Nodes' />
                      </ListItem>
                    </Link>
                  )}
                </List>
              </CanvasItems>

              <CanvasItems
                setCollapsed={setsubCollapsed}
                collapsed={subCollapsed}
                icon={<AccountTreeIcon fontSize='small' />}
                title='SUBTREE LIST'
              >
                <List className={classes.inList}>
                  {subtreeState.subtrees.length > 0 ? (
                    subtreeState.subtrees.map((e, i) => (
                      <>
                        <div key={i}>
                          <SidebarItem
                            type='input-output'
                            ports={portConf}
                            properties={{
                              custom: e.id
                            }}
                            setChangeTrigger={setChangeTrigger}
                          />
                        </div>
                      </>
                    ))
                  ) : (
                    <Link className={classes.linkStyle} to='/subtree-library'>
                      <ListItem button>
                        <ListItemText disableTypography primary='Create Subtrees' />
                      </ListItem>
                    </Link>
                  )}
                </List>
              </CanvasItems>

              <CanvasItems
                setCollapsed={setalgoCollapsed}
                collapsed={algoCollapsed}
                icon={<AccountTreeIcon fontSize='small' />}
                title='ALGORITHM LIST'
              >
                <List className={classes.inList}>
                  {algorithmState.algorithms.length > 0 ? (
                    algorithmState.algorithms.map((e, i) => (
                      <>
                        <div key={i}>
                          <SidebarItem
                            type='input-output'
                            ports={portConf}
                            properties={{
                              custom: e.id
                            }}
                            setChangeTrigger={setChangeTrigger}
                          />
                        </div>
                      </>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText disableTypography secondary='No Algorithms Found' />
                    </ListItem>
                  )}
                </List>
              </CanvasItems>
            </Sidebar>
          </Page>
        </Paper>
      </form>
      <Backdrop className={classes.backdrop} open={isActionLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <AlgoCloneWarning
        open={cloneWarnOpen}
        onClose={() => {
          setCloneWarnOpen(false)
        }}
      />

      <DeleteDialog
        open={openDel}
        selected={getValues('id')}
        onClose={handleClose}
      />
    </>
  )
}

export default AlgorithmTree
