export const AUTH_TYPES = {
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT'
}

const AuthReducer = (state, action) => {
  switch (action.type) {
    case AUTH_TYPES.SIGN_IN:
      return {
        ...state,
        username: action.payload.username,
        authToken: action.payload.authToken
      }
    case AUTH_TYPES.SIGN_OUT:
      return {
        ...state,
        username: null,
        authToken: null
      }
    default:
      return state
  }
}

export default AuthReducer
