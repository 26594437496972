import { Tooltip } from '@material-ui/core'
import React, { useContext, useMemo } from 'react'
import { NodeContext } from '../../states/NodeContextProvider'
import { Outer } from './Outer'

export const NodeInnerCustom = ({ node, config }) => {
  const { nodeState } = useContext(NodeContext)
  const nodeResult = useMemo(
    () => nodeState.nodes.find(e => e.id === node.properties.custom),
    [node, nodeState.nodes]
  )

  return (
    <Outer>
      <Tooltip
        disableHoverListener={false}
        title={nodeResult ? nodeResult.expression : node.properties.custom}
        aria-label='add'
      >
        <p style={{ color: 'black' }}>{node.properties.custom}</p>
      </Tooltip>
    </Outer>
  )
}
