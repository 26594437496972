import React, { useEffect, useReducer } from 'react'

const createStorage = provider => ({
  get (key, initialState) {
    const json = provider.getItem(key)
    return json === null
      ? typeof initialState === 'function'
        ? initialState()
        : initialState
      : JSON.parse(json)
  },
  set (key, value) {
    provider.setItem(key, JSON.stringify(value))
  }
})

const usePersistedReducer = (reducer, initialState, init, key, storage) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    storage.get(key, initialState),
    init
  )

  useEffect(() => {
    storage.set(key, state)
  }, [state, key, storage])

  return [state, dispatch]
}

export const createPersistedReducer = (key, provider = window.localStorage) => {
  if (provider) {
    const storage = createStorage(provider)
    return (reducer, initialState, init) =>
      usePersistedReducer(reducer, initialState, init, key, storage)
  }
  return useReducer
}
