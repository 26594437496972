import Axios from 'axios'
import { apiUrl } from '../config/environment'
import cal, { days } from './function-library/expressionFunctions'
const evaluate = require('static-eval')
const esprima = require('esprima')

const isNoMatch = (toCheck, toMatch) => {
  let noMatch = true
  for (const e of toMatch) {
    if (toCheck.includes(e)) {
      noMatch = false
      break
    }
  }
  return noMatch
}

export const evaluateNode = async (input, body, allExternals) => {
  let error = []
  let ast = ''
  let value = null
  Object.keys(body).forEach(e => {
    if (isNoMatch(e, ['_name', 'cutoff', '_id', ...allExternals])) {
      body[e] = body[e] * 1
    }
  })

  const externals = {
    lookup: async function (
      param,
      tableIdDotcolToMatch,
      valToCol,
      expIfMultiple = 'true'
    ) {
      const splitted = tableIdDotcolToMatch.split('.').map(e => e.trim())
      const [tableID, columnName] = splitted
      const authToken = JSON.parse(localStorage.getItem('state')).authToken
      const axCall = await Axios.post(
        `${apiUrl}external-entity/view/`,
        { id: tableID },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      )
      const external = {}
      axCall.data.data.forEach(e => {
        external[e.id] = e.row
      })
      let matched = []
      for (const value of Object.values(external)) {
        const valJSON = value
        if (valJSON[columnName] === param) {
          matched.push(valJSON)
        }
      }

      if (matched.length > 1) {
        for (const e of matched) {
          const eparsed = esprima.parseScript(expIfMultiple)
          error = eparsed.errors
          const ast = eparsed.body[0].expression
          const result = await evaluate(ast, { ...externals, row: e })
          if (result) {
            matched = e[valToCol]
            break
          } else {
            matched = false
          }
        }
      } else if (matched.length === 1) {
        matched = matched[0][valToCol]
      } else {
        matched = false
      }
      return matched
    },
    ...cal
  }
  Object.assign(externals, body)
  const supportedFuns = [
    'max',
    'min',
    'avg',
    'abs',
    'search',
    'currentHour',
    'currentDay',
    'between',
    'floor',
    'ceil',
    'round',
    'diffp',
    'lookup',
    'zeroify'
  ]
  try {
    const eparsed = esprima.parseScript(input, { tolerant: true }, node => {
      if (node.name === 'console') {
        throw new Error('Sorry console statements are not supported!')
      }
      if (node.callee) {
        if (!supportedFuns.includes(node.callee.name)) {
          throw new Error(
            `Sorry, unsupported function call: ${node.callee.name}`
          )
        }
      }
      if (
        node.type === 'VariableDeclarator' ||
        node.type === 'VariableDeclaration'
      ) {
        throw new Error(`Sorry, variable declarations are not supported!`)
      }

      if (node.type === 'BinaryExpression') {
        if (node.left.type === 'Identifier') {
          if (!externals.hasOwnProperty(node.left.name)) {
            externals[node.left.name] = 0
          }
        }
        if (node.right.type === 'Identifier') {
          if (!externals.hasOwnProperty(node.right.name)) {
            externals[node.right.name] = 0
          }
        }
      }

      if (node.type === 'ConditionalExpression') {
        if (node.alternate.type === 'Identifier') {
          if (!externals.hasOwnProperty(node.alternate.name)) {
            externals[node.alternate.name] = 0
          }
        }
        if (node.consequent.type === 'Identifier') {
          if (!externals.hasOwnProperty(node.consequent.name)) {
            externals[node.consequent.name] = 0
          }
        }
      }

    })
    
    error = eparsed.errors
    ast = eparsed.body[0].expression

    const evaluatedValue = await evaluate(ast, externals)
    if (
      evaluatedValue === true ||
      evaluatedValue === false ||
      days.includes(evaluatedValue)
    ) {
      value = evaluatedValue
    } else {
      value = evaluatedValue * 1
    }
  } catch (err) {
    error.push(err.toString())
  }

  return { value, error }
}
