import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Box, Button, makeStyles, Paper, TextField } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { Context } from '../states/AuthContextProvider'
import Axios from 'axios'
import { apiUrl } from '../config/environment'
import { Alert, AlertTitle } from '@material-ui/lab'
import DeleteIcon from '@material-ui/icons/Delete'
import { CSVLink } from 'react-csv'
import { parse } from 'csv'
import DeleteDialog from './fragments/DeleteDialog'
const useStyles = makeStyles(theme => ({
  back: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    color: 'white',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  box: {
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 0,
    paddingRight: 0
  },
  chip: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  btn: {
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  input: {
    display: 'none'
  }
}))

const SaveMock = ({ mockData, setMockData, setSwitchState, setLoading }) => {
  const classes = useStyles()
  const { handleSubmit, control, setValue } = useForm()
  const [isSaveInput, setIsSaveInput] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [savedMocks, setSavedMocks] = useState({ data: [] })
  const [refresh, setRefresh] = useState(false)
  const [isError, setIsError] = useState(false)
  const [selectedMock, setSelectedMock] = useState(null)
  const { state } = useContext(Context)
  const [openDel, setOpenDel] = useState(false)

  const httpOptions = {
    headers: {
      Authorization: `Bearer ${state.authToken}`
    }
  }
  const onSubmitSaveMock = useCallback(
    async data => {
      setLoading(true)
      try {
        if (!isEdit) {
          await Axios.post(
            `${apiUrl}save-mock/create`,
            {
              mockID: data.mockID,
              mockData
            },
            httpOptions
          )
          setRefresh(!refresh)
          setIsSaveInput(false)
          setLoading(false)
        } else {
          await Axios.post(
            `${apiUrl}save-mock/update`,
            {
              mockID: selectedMock,
              mockData
            },
            httpOptions
          )
          setRefresh(!refresh)
          setIsEdit(false)
          setLoading(false)
        }
      } catch (error) {
        setIsError(error.response.data.msg)
        setIsSaveInput(false)
        setIsEdit(false)
        setLoading(false)
      }
    },
    // eslint-disable-next-line
    [httpOptions]
  )

  const handleChipClick = (ev, e) => {
    setIsEdit(true)
    setSelectedMock(e.mockID)
    setMockData(e.mockData)
    setSwitchState(true)
  }

  const handleDeleteClick = useCallback(
    async () => {
      setLoading(true)
      try {
        setIsEdit(false)
        await Axios.post(
          `${apiUrl}save-mock/delete`,
          {
            mockID: selectedMock,
            mockData
          },
          httpOptions
        )
        setRefresh(!refresh)
        setLoading(false)
      } catch (error) {
        setIsError(error.response.data.msg)
        setIsEdit(false)
        setLoading(false)
      }
    }, // eslint-disable-next-line
    [mockData]
  )
  const handleClose = confirmed => {
    setOpenDel(false)
    if (confirmed) {
      handleDeleteClick()
    }
  }
  useEffect(() => {
    async function fetchData () {
      setLoading(true)
      try {
        const response = await Axios.get(`${apiUrl}save-mock/view`, httpOptions)
        setSavedMocks(response.data)
        setLoading(false)
      } catch (error) {
        setIsError(error.response.data.msg)
        setLoading(false)
      }
    }
    fetchData()
    // eslint-disable-next-line
  }, [refresh])

  const handleImport = e => {
    const reader = new FileReader()
    reader.onload = () => {
      const ls = reader.result
      parse(
        ls,
        {
          columns: true,
          delimiter: ',',
          ltrim: true,
          rtrim: true,
          bom: true,
          relax: true,
          skip_lines_with_empty_values: true
        },
        (err, data) => {
          if (err) {
            console.log(err)
          } else {
            setMockData(data)
          }
        }
      )
    }
    reader.readAsText(e.target.files[0])
    setSwitchState(true)
  }

  return (
    <>
      <div className='save-mock'>
        <Box
          display='flex'
          flexDirection='row-reverse'
          p={1}
          m={1}
          className={classes.box}
        >
          <form onSubmit={handleSubmit(onSubmitSaveMock)}>
            {isEdit ? (
              <>
                <Button
                  style={{ marginLeft: '5px' }}
                  color='secondary'
                  variant='contained'
                  aria-label='delete'
                  onClick={() => setOpenDel(true)}
                  startIcon={<DeleteIcon />}
                  disableElevation
                >
                  Delete
                </Button>
                <Button
                  style={{ marginLeft: '5px' }}
                  color='secondary'
                  variant='outlined'
                  aria-label='add'
                  onClick={() => setIsEdit(false)}
                >
                  Cancel Edit
                </Button>
              </>
            ) : isSaveInput ? (
              <>
                <Controller
                  as={
                    <TextField
                      size='small'
                      label='mock name'
                      variant='outlined'
                      style={{ marginRight: '5px' }}
                    />
                  }
                  name='mockID'
                  rules={{ required: true }}
                  control={control}
                  required
                  defaultValue=''
                />

                <Button
                  color='primary'
                  aria-label='add'
                  className={classes.back}
                  type='submit'
                >
                  Save
                </Button>

                <Button
                  color='secondary'
                  aria-label='add'
                  type='button'
                  onClick={() => {
                    setIsSaveInput(!isSaveInput)
                  }}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                color='primary'
                aria-label='add'
                className={classes.back}
                type='button'
                onClick={() => setIsSaveInput(!isSaveInput)}
              >
                Save Input
              </Button>
            )}

            {isEdit && (
              <Button
                color='primary'
                aria-label='add'
                className={classes.back}
                onClick={setValue('mockID', selectedMock)}
                type='submit'
              >
                Save
              </Button>
            )}
          </form>
          <CSVLink
            className={classes.btn}
            filename={'export.csv'}
            style={{ textDecoration: 'none' }}
            data={mockData}
            target='_blank'
          >
            <Button variant='outlined'>Export CSV</Button>
          </CSVLink>

          <input
            className={classes.input}
            id='contained-button-file'
            multiple
            type='file'
            onChange={handleImport}
          />
          <label className={classes.btn} htmlFor='contained-button-file'>
            <Button variant='outlined' component='span'>
              Import CSV
            </Button>
          </label>
        </Box>
      </div>

      {isError && (
        <Alert
          variant='outlined'
          severity='error'
          onClose={() => {
            setIsError(false)
          }}
        >
          <AlertTitle>Error</AlertTitle>
          {isError}
        </Alert>
      )}

      <Paper elevation={1} className='save-mock-list'>
        <Box p={1} m={1} className={classes.chip}>
          {savedMocks.data.length > 0 ? (
            savedMocks.data.map((e, i) => (
              <Button
                key={i}
                color='primary'
                size='medium'
                variant='outlined'
                onClick={ev => handleChipClick(ev, e)}
              >
                {e.mockID}
              </Button>
            ))
          ) : (
            <p>No Data Found</p>
          )}
        </Box>
      </Paper>
      <DeleteDialog
        open={openDel}
        selected={selectedMock}
        onClose={handleClose}
      />
    </>
  )
}

export default SaveMock
