export const ALGORITHM_TYPES = {
  FETCH_ALGORITHMS: 'FETCH_ALGORITHMS',
  CREATE_ALGORITHM: 'CREATE_ALGORITHM',
  EDIT_ALGORITHM: 'EDIT_ALGORITHM',
  DELETE_ALGORITHM: 'DELETE_ALGORITHM',
  SELECT_ALGORITHM: 'SELECT_ALGORITHM'
}

const AlgorithmReducer = (state, action) => {
  switch (action.type) {
    case ALGORITHM_TYPES.FETCH_ALGORITHMS:
      return {
        ...state,
        algorithms: action.payload
      }
    case ALGORITHM_TYPES.CREATE_ALGORITHM:
      return {
        ...state,
        algorithms: [...state.algorithms, action.payload]
      }
    case ALGORITHM_TYPES.EDIT_ALGORITHM:
      return {
        ...state,
        algorithms: [
          ...state.algorithms.filter(e => e.id !== action.payload.id),
          action.payload
        ]
      }
    case ALGORITHM_TYPES.DELETE_ALGORITHM:
      return {
        ...state,
        algorithms: [
          ...state.algorithms.filter(e => e.id !== action.payload.id)
        ]
      }
      case ALGORITHM_TYPES.SELECT_ALGORITHM:
      return {
        ...state,
        selectedAlgorithm: action.payload
      }
    default:
      return state
  }
}

export default AlgorithmReducer
