import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import List from '@material-ui/core/List'
import SyncProblemIcon from '@material-ui/icons/SyncProblem'
import ExtensionIcon from '@material-ui/icons/Extension';
import ScheduleIcon from '@material-ui/icons/Schedule'
import { Link } from 'react-router-dom'

export const MainListItems = ({ handlePopoverClose, handlePopoverOpen }) => {
  return (
    <>
      <List>
        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/dashboard'
        >
          <ListItem
            button
            onMouseEnter={e => handlePopoverOpen(e, 'Dashboard')}
            onMouseLeave={handlePopoverClose}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary='Dashboard' />
          </ListItem>
        </Link>

        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/node-library'
        >
          <ListItem
            button
            onMouseEnter={e => handlePopoverOpen(e, 'Node Library')}
            onMouseLeave={handlePopoverClose}
          >
            <ListItemIcon>
              <AddCircleOutlineIcon className='node-lib' />
            </ListItemIcon>
            <ListItemText primary='Node Library' />
          </ListItem>
        </Link>

        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/subtree-library'
        >
          <ListItem
            button
            onMouseEnter={e => handlePopoverOpen(e, 'Sub-Tree Library')}
            onMouseLeave={handlePopoverClose}
          >
            <ListItemIcon>
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText primary='Sub-Tree Library' />
          </ListItem>
        </Link>

        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/algorithm-library'
        >
          <ListItem
            button
            onMouseEnter={e => handlePopoverOpen(e, 'Algorithm Library')}
            onMouseLeave={handlePopoverClose}
          >
            <ListItemIcon>
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText primary='Algorithm Library' />
          </ListItem>
        </Link>

        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/scheduling'
        >
          <ListItem
            button
            onMouseEnter={e => handlePopoverOpen(e, 'Scheduling')}
            onMouseLeave={handlePopoverClose}
          >
            <ListItemIcon>
              <ScheduleIcon />
            </ListItemIcon>
            <ListItemText primary='Scheduling' />
          </ListItem>
        </Link>

      </List>
      <List>
        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/pre-calculation'
        >
          <ListItem
            onMouseEnter={e => handlePopoverOpen(e, 'Pre Calculation')}
            onMouseLeave={handlePopoverClose}
            button
          >
            <ListItemIcon>
              <SyncProblemIcon />
            </ListItemIcon>
            <ListItemText primary='Pre Calculation' />
          </ListItem>
        </Link>
        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/post-calculation'
        >
          <ListItem
            onMouseEnter={e => handlePopoverOpen(e, 'Post Calculation')}
            onMouseLeave={handlePopoverClose}
            button
          >
            <ListItemIcon>
              <SyncProblemIcon />
            </ListItemIcon>
            <ListItemText primary='Post Calculation' />
          </ListItem>
        </Link>

        <Link
          style={{ textDecoration: 'none', color: 'inherit' }}
          to='/external-library'
        >
          <ListItem
            onMouseEnter={e => handlePopoverOpen(e, 'External Library')}
            onMouseLeave={handlePopoverClose}
            button
          >
            <ListItemIcon>
              <ExtensionIcon />
            </ListItemIcon>
            <ListItemText primary='External Library' />
          </ListItem>
        </Link>
      </List>
    </>
  )
}
