import {
  Box,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import React from 'react'

const useStyles = makeStyles(theme => ({
  inBox: {
    width: '100%'
  }
}))

const CanvasItems = ({ setCollapsed, collapsed, children, icon, title }) => {
  const classes = useStyles()
  return (
    <>
      <ListItem
        button
        className='collap-item'
        onClick={() => setCollapsed(!collapsed)}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText disableTypography primary={title} />
        {collapsed ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        className='algo-drop'
        in={collapsed}
        collapsedHeight='0px'
      >
        <Box display='flex' justifyContent='center'>
          <Box borderColor='primary.main' border={1} className={classes.inBox}>
            {children}
          </Box>
        </Box>
      </Collapse>
    </>
  )
}

export default CanvasItems
