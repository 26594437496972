import React, { createRef, useCallback } from 'react'
import {
  Paper,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import { useContext } from 'react'
import { AlgorithmContext } from '../states/AlgorithmContextProvider'
import MaterialTable from 'material-table'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import AvailListDialog from '../components/AvailListDialog'

const columns = [
  {
    title: 'ID',
    tooltip: 'Post Calculation ID',
    field: 'id',
    editable: 'onAdd',
    defaultSort: 'asc'
  },
  {
    title: 'Expression',
    tooltip: 'Expression in Alpha-Numeric Order',
    field: 'expression',
    editComponent: props => (
      <TextField
        fullWidth
        multiline
        type='text'
        placeholder='Expression'
        defaultValue={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    )
  }
]

const PostCalculation = ({ setTitle }) => {
  setTitle('Post Calculation')
  const {
    algorithmState,
    editAlgorithm,
    fetchAlgorithms,
    selectAlgorithm,
    isError,
    setIsError
  } = useContext(AlgorithmContext)

  const postCalTable = createRef()
  const getAlgo = algorithmState.algorithms.find(
    e => e.id === algorithmState.selectedAlgorithm
  )
  const data = getAlgo ? getAlgo.postCal : []

  const onRowAdd = useCallback(
    async data => {
      await editAlgorithm({
        id: getAlgo.id,
        description: getAlgo.description,
        conf: getAlgo.conf,
        preCal: getAlgo.preCal,
        postCal: [
          ...getAlgo.postCal,
          { id: data.id, expression: data.expression }
        ]
      })
    },
    //eslint-disable-next-line
    [algorithmState]
  )

  const onRowDelete = useCallback(
    async data => {
      await editAlgorithm({
        id: getAlgo.id,
        description: getAlgo.description,
        conf: getAlgo.conf,
        preCal: getAlgo.preCal,
        postCal: [...getAlgo.postCal.filter(e => e.id !== data.id)]
      })
    },
    //eslint-disable-next-line
    [algorithmState]
  )

  const onRowUpdate = useCallback(
    async data => {
      await editAlgorithm({
        id: getAlgo.id,
        description: getAlgo.description,
        conf: getAlgo.conf,
        preCal: getAlgo.preCal,
        postCal: [
          ...getAlgo.postCal.filter(e => e.id !== data.id),
          { id: data.id, expression: data.expression }
        ]
      })
    },
    //eslint-disable-next-line
    [algorithmState]
  )

  return (
    <>
      <Alert severity='info' style={{ marginBottom: '15px' }}>
        Post-calculations are expressions similar to “nodes” but modifies
        parameters that are passed to the algorithm before we pass the values to
        the decision tree.{' '}
        <Link style={{ textDecoration: 'none' }} to='/docs/post-calculation'>
          <strong> Learn more </strong>
        </Link>
      </Alert>

      {isError && (
        <Alert
          variant='outlined'
          severity='error'
          onClose={() => {
            setIsError(false)
          }}
        >
          <AlertTitle>Error</AlertTitle>
          {isError}
        </Alert>
      )}
      <AvailListDialog postCal />
      <Paper elevation={1}>
        <Box display='flex' p={1} m={1}>
          <FormControl
            variant='outlined'
            style={{ width: '100%', padding: '10px' }}
          >
            <InputLabel>Algorithm ID</InputLabel>

            <Select
              value={algorithmState.selectedAlgorithm}
              onChange={e => {
                selectAlgorithm(e.target.value)
              }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {algorithmState.algorithms.map((e, i) => (
                <MenuItem key={i} value={e.id}>
                  {e.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Paper>
      <MaterialTable
        style={{ width: '100%' }}
        tableRef={postCalTable}
        title='Post Calculation'
        columns={columns}
        data={data}
        editable={{
          onRowAdd: onRowAdd,
          onRowUpdate: onRowUpdate,
          onRowDelete: onRowDelete
        }}
        actions={[
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => fetchAlgorithms()
          }
        ]}
        options={{
          sorting: true
        }}
      />
    </>
  )
}

export default PostCalculation
