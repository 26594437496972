import React, {
  useContext,
  createRef,
  useState,
  useCallback,
  useEffect
} from 'react'
import { Context } from '../../states/AuthContextProvider'
import MaterialTable from 'material-table'
import axios from 'axios'
import { apiUrl } from '../../config/environment'

const mapperColumn = [
  {
    title: 'Key',
    tooltip: 'Account Name from Main Table',
    field: 'key'
  },
  {
    title: 'Value',
    tooltip: 'Account Name to be Mapped into',
    field: 'value'
  }
]
const MapperCutoffTable = ({ version }) => {
  const { state } = useContext(Context)
  const httpOptions = {
    headers: {
      Authorization: `Bearer ${state.authToken}`
    }
  }
  const mapperTableRef = createRef()
  const [mapperData, setMapperData] = useState([])
  const [triggerHook, setTriggerHook] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const onRowAddMapper = useCallback(
    async newData => {
      await axios.post(
        `${apiUrl}cut-off/mapper/create`,
        {
          version,
          mapper: [
            {
              key: newData.key,
              value: newData.value
            }
          ]
        },
        httpOptions
      )
      setTriggerHook(!triggerHook)
      // eslint-disable-next-line
    },
    // eslint-disable-next-line
    [triggerHook]
  )
  const onRowUpdateMapper = useCallback(
    async (newData, oldData) => {
      await axios.post(
        `${apiUrl}cut-off/mapper/update`,
        {
          version,
          mapper: [
            {
              key: newData.key,
              value: newData.value
            }
          ]
        },
        httpOptions
      )
      setTriggerHook(!triggerHook)
    },
    // eslint-disable-next-line
    [triggerHook]
  )
  const onRowDeleteMapper = useCallback(
    async oldData => {
      await axios.post(
        `${apiUrl}cut-off/mapper/delete`,
        {
          version,
          mapper: [
            {
              key: oldData.key
            }
          ]
        },
        httpOptions
      )
      setTriggerHook(!triggerHook)
    },
    // eslint-disable-next-line
    [triggerHook]
  )
  useEffect(() => {
    setIsLoading(true)
    async function fetchData () {
      const response = await axios(
        `${apiUrl}cut-off/mapper/view/${version}`,
        httpOptions
      )
      const x = []
      response.data.mapper.map(e =>
        x.push({
          key: e.key,
          value: e.value
        })
      )
      setMapperData(x)
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line
  }, [triggerHook])
  return (
    <>
      <MaterialTable
        tableRef={mapperTableRef}
        title='Mapper Table'
        columns={mapperColumn}
        data={mapperData}
        isLoading={isLoading}
        editable={{
          onRowAdd: onRowAddMapper,
          onRowUpdate: onRowUpdateMapper,
          onRowDelete: onRowDeleteMapper
        }}
        actions={[
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => setTriggerHook(!triggerHook)
          }
        ]}
      />
    </>
  )
}

export default MapperCutoffTable
