import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  TextField
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import Axios from 'axios'
import MaterialTable from 'material-table'
import React, { useCallback, useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { apiUrl } from '../config/environment'
import { Context } from '../states/AuthContextProvider'
import { ExternalContext } from '../states/ExternalContextProvider'
import { CSVLink } from 'react-csv'
import { parse } from 'csv'
import cloneDeep from 'lodash/cloneDeep'
const useStyles = makeStyles(theme => ({
  back: {
    background: 'linear-gradient(to right, #00b4db, #0083b0)',
    margin: '0',
    color: 'white'
  },
  chip: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  paperChip: {
    marginTop: '15px',
    borderRadius: '10px',
    padding: theme.spacing(0.5)
  },
  chipButton: {
    textTransform: 'none'
  },

  idField: {
    marginRight: '15px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  headerField: {
    marginRight: '15px',
    maxWidth: '350px'
  },
  input: {
    display: 'none'
  }
}))

const ExternalLibrary = ({ setTitle }) => {
  setTitle('External Library')
  const {
    externalState,
    createExternal,
    selectExternal,
    isActionLoading,
    setIsActionLoading,
    isError,
    setIsError,
    editExternal,
    deleteExternal
  } = useContext(ExternalContext)
  const { state } = useContext(Context)
  const httpOptions = {
    headers: {
      Authorization: `Bearer ${state.authToken}`
    }
  }
  const classes = useStyles()
  const [tableData, setTableData] = useState([])
  const [columns, setColumns] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [isImport, setIsImport] = useState(false)
  const { handleSubmit, control, setValue } = useForm()

  const onSubmit = async data => {
    const headers = data.headers.split(',').map(e => e.trim())
    await createExternal({ id: data.id, headers })
    if (isImport) {
      setIsActionLoading(true)
      try {
        for await (const e of tableData) {
          const rowData = cloneDeep(e)
          delete rowData.tableData
          await Axios.post(
            `${apiUrl}external-entity/create`,
            {
              id: externalState.selectedExternal.id,
              rowData
            },
            httpOptions
          )
        }
      } catch (error) {
        console.log(error.toString())
        setIsActionLoading(false)
      }
      setIsActionLoading(false)
      setIsImport(false)
    }
    cancelEdit()
  }

  const onDelete = async id => {
    try {
      deleteExternal({ id })
      cancelEdit()
    } catch (error) {
      console.log(error.toString())
    }
  }

  const handleSelectClick = async id => {
    setIsEdit(true)
    const selected = externalState.externals.find(e => e.id === id)
    selectExternal(selected)
    const cols = []
    for (const header of selected.headers) {
      cols.push({
        title: header,
        field: header,
        tooltip: `Accessible by ${id}.${header}`
      })
    }
    setColumns(cols)
    try {
      const res = await Axios.post(
        `${apiUrl}external-entity/view`,
        { id },
        httpOptions
      )
      const rows = []
      for (const rowData of res.data.data) {
        rows.push({ ...rowData.row, id: rowData.id })
      }
      setTableData(rows)
      setValue('id', id)
      setValue('headers', selected.headers)
    } catch (error) {
      console.log(error.toString())
    }
  }

  const cancelEdit = ev => {
    setValue('id', '')
    setValue('headers', '')
    setIsEdit(false)
    selectExternal({ id: '' })
    setTableData([])
    setColumns([])
  }

  const onRowAdd = useCallback(
    async data => {
      try {
        const response = await Axios.post(
          `${apiUrl}external-entity/create`,
          {
            id: externalState.selectedExternal.id,
            rowData: data
          },
          httpOptions
        )
        setTableData(t => [...t, response.data.data])
      } catch (error) {
        console.log(error.toString())
        setIsImport(false)
      }
    },
    [externalState.selectedExternal.id, httpOptions]
  )

  const onRowUpdate = useCallback(
    async (newData, oldData) => {
      try {
        const response = await Axios.post(
          `${apiUrl}external-entity/update`,
          {
            id: externalState.selectedExternal.id,
            rowID: newData.id,
            rowData: newData
          },
          httpOptions
        )
        setTableData(t => [
          ...t.filter(e => e.id !== oldData.id),
          response.data.data
        ])
      } catch (error) {
        console.log(error.toString())
      }
    },
    [externalState.selectedExternal.id, httpOptions]
  )

  const onRowDelete = useCallback(
    async data => {
      try {
        await Axios.post(
          `${apiUrl}external-entity/delete`,
          {
            id: externalState.selectedExternal.id,
            rowID: data.id
          },
          httpOptions
        )
        setTableData(t => [...t.filter(e => e.id !== data.id)])
      } catch (error) {
        console.log(error.toString())
      }
    },
    [externalState.selectedExternal.id, httpOptions]
  )

  const onColumnAdd = useCallback(
    async (data) => {
      const colFields = columns.map(e => { return { old: e.field, new: e.field } })
      const updatedHeaders = [...colFields, { old: null, new: data.field }]
      await editExternal({ id: externalState.selectedExternal.id, headers: updatedHeaders })
      cancelEdit()
    },
    // eslint-disable-next-line
    [externalState.selectedExternal, columns, editExternal]
  )

  const onColumnDelete = useCallback(
    async (data) => {
      const updatedHeaders = columns.map(e => { return { old: e.field, new: e.field } })
      updatedHeaders.forEach((e, i) => {
        if (e.new === data.field) {
          updatedHeaders[i].new = null
        }
      })
      await editExternal({ id: externalState.selectedExternal.id, headers: updatedHeaders })
      cancelEdit()
    },
    // eslint-disable-next-line
    [externalState.selectedExternal, columns, editExternal]
  )

  const onColumnUpdate = useCallback(
    async (newData, oldData) => {
      const updatedHeaders = []
      columns.forEach(e => {
        if (e.field === oldData.field) {
          updatedHeaders.push({ old: e.field, new: newData.field })
        } else {
          updatedHeaders.push({ old: e.field, new: e.field })
        }
      })
      await editExternal({ id: externalState.selectedExternal.id, headers: updatedHeaders })
      cancelEdit()
    },
    // eslint-disable-next-line
    [externalState.selectedExternal, columns, editExternal]
  )

  const handleImport = e => {
    const reader = new FileReader()

    reader.onload = () => {
      const ls = reader.result
      parse(
        ls,
        {
          columns: true,
          delimiter: ',',
          ltrim: true,
          rtrim: true,
          bom: true,
          skip_empty_lines: true,
          relax: true
        },
        (err, parsedData) => {
          if (err) {
            console.log(err)
          } else {
            const cols = Object.keys(parsedData[0])
            setValue('headers', cols.toString())
            const colsForTable = []
            for (const header of cols) {
              colsForTable.push({
                title: header,
                field: header
              })
            }
            setColumns(colsForTable)
            setTableData(parsedData)
            setIsImport(true)
          }
        }
      )
    }
    reader.readAsText(e.target.files[0])
    const name = e.target.files[0].name.substring(
      0,
      e.target.files[0].name.length - 4
    )
    selectExternal({ id: name })
    setValue('id', name)
  }

  return (
    <>
      {isError && (
        <Alert
          variant='outlined'
          severity='error'
          onClose={() => {
            setIsError(false)
          }}
        >
          <AlertTitle>Error</AlertTitle>
          {isError}
        </Alert>
      )}

      {isImport && (
        <Alert
          variant='outlined'
          severity='error'
          onClose={() => {
            setIsError(false)
          }}
        >
          <AlertTitle>DATA IS NOT SAVED YET!</AlertTitle>
          CSV has been parsed, please review and press "ADD EXTERNAL"
        </Alert>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display='flex' p={1} m={1} flexDirection='row-reverse'>
          {isEdit
            ? (
              <>
                <Button
                  color='secondary'
                  aria-label='add'
                  style={{ margin: '0 15px' }}
                  type='button'
                  variant='outlined'
                  onClick={e=>{
                    e.preventDefault()
                    cancelEdit()
                  }}
                >
                  Cancel
                </Button>

                <Button
                  color='secondary'
                  aria-label='add'
                  style={{ margin: '0 15px' }}
                  type='button'
                  variant='contained'
                  onClick={ev => {
                    ev.preventDefault()
                    onDelete(externalState.selectedExternal.id)
                  }}
                >
                  Delete
                </Button>
              </>)
            : (
              <>
                <Button
                  color='primary'
                  aria-label='add'
                  className={classes.back}
                  type='submit'
                >
                  Add External
                </Button>

                <Controller
                  as={
                    <TextField
                      label='Columns'
                      variant='outlined'
                      required
                      className={classes.headerField}
                      fullWidth
                      placeholder='insert columns separated by comma. eg. column 1, column 2, column 3 ...'
                    />
                  }
                  name='headers'
                  control={control}
                  defaultValue=''
                />
              </>)}

          <Controller
            as={
              <TextField
                label='ID'
                variant='outlined'
                required
                disabled={isEdit}
                placeholder='External ID'
                className={classes.idField}
              />
            }
            name='id'
            control={control}
            defaultValue=''
          />

        </Box>
        {!isEdit && (
          <Box display='flex' p={1} m={1} flexDirection='row-reverse'>
            <input
              className={classes.input}
              id='contained-button-file'
              multiple
              type='file'
              onChange={handleImport}
            />
            <label className={classes.btn} htmlFor='contained-button-file'>
              <Button variant='outlined' component='span'>
                Import CSV
              </Button>
            </label>
          </Box>
        )}
      </form>

      {isEdit && (
        <Box display='flex' p={1} m={1} flexDirection='row-reverse'>
          <CSVLink
            filename='export.csv'
            style={{ textDecoration: 'none', margin: '0 15px' }}
            data={tableData}
            type='button'
            target='_blank'
          >
            <Button variant='outlined'>Export CSV</Button>
          </CSVLink>
        </Box>
      )}

      <Paper elevation={1} className={classes.paperChip}>
        <Box component='span' p={1} m={1} className={classes.chip}>
          {externalState.externals.map((e, i) => (
            <Button
              color='primary'
              className={classes.chipButton}
              key={i}
              variant='outlined'
              onClick={ev => handleSelectClick(e.id)}
            >
              {e.id}
            </Button>
          ))}
        </Box>
      </Paper>

      {(isImport || isEdit) &&
        <MaterialTable
          title='Columns'
          columns={[{ title: 'Columns', field: 'field', tooltip: 'columns of the table' }]}
          data={columns}
          style={{ marginTop: '15px' }}
          editable={{
            onRowAdd: onColumnAdd,
            onRowUpdate: onColumnUpdate,
            onRowDelete: onColumnDelete
          }}
        />}

      <MaterialTable
        title='Data'
        columns={columns}
        data={tableData}
        style={{ marginTop: '15px' }}
        editable={{
          onRowAdd: onRowAdd,
          onRowUpdate: onRowUpdate,
          onRowDelete: onRowDelete
        }}
      />
      <Backdrop className={classes.backdrop} open={isActionLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  )
}

export default ExternalLibrary
