export const NODE_TYPES = {
  FETCH_NODE: 'FETCH_NODES',
  CREATE_NODE: 'CREATE_NODE',
  DELETE_NODE: 'DELETE_NODE',
  UPDATE_NODE: 'UPDATE_NODE'
}

const NodeReducer = (state, action) => {
  switch (action.type) {
    case NODE_TYPES.FETCH_NODES:
      return {
        ...state,
        nodes: action.payload.nodes
      }
    case NODE_TYPES.UPDATE_NODE:
      return {
        ...state,
        nodes: [
          ...state.nodes.filter(e => e.id !== action.payload.oldData.id),
          action.payload.newData
        ]
      }
    case NODE_TYPES.CREATE_NODE:
      return {
        ...state,
        nodes: [...state.nodes, action.payload]
      }
    case NODE_TYPES.DELETE_NODE:
      return {
        ...state,
        nodes: state.nodes.filter(e => e.id !== action.payload.id)
      }
    default:
      return state
  }
}

export default NodeReducer
