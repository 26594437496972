import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NodeContext } from '../../states/NodeContextProvider'
const CloneDialog = props => {
  const { setOpen, open, selectedRows } = props
  const { handleSubmit, control } = useForm()
  const { createNode } = useContext(NodeContext)

  const handleClose = () => {
    setOpen(false)
  }
  const onSubmit = async data => {
    for await (const e of selectedRows) {
      await createNode({
        ...e,
        id: e.id + data.suffix
      })
    }
    handleClose()
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Add Suffix</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              as={
                <TextField
                  id='outlined-basic'
                  label='Suffix'
                  variant='outlined'
                  fullWidth
                  required
                  placeholder='Clone Suffix'
                  autoFocus
                />
              }
              name='suffix'
              control={control}
              defaultValue=''
            />
            <Button type='submit' color='primary' fullWidth>
              clone
            </Button>
          </form>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default CloneDialog
