import React, {
  createContext,
  useReducer,
  useContext,
  useState,
  useCallback
} from 'react'
import NodeReducer, { NODE_TYPES } from './reducers/nodeReducer'
import { Context } from './AuthContextProvider'
import Axios from 'axios'
import { apiUrl } from '../config/environment'

const initialState = {
  nodes: []
}

const NodeContextProvider = ({ children }) => {

  const [nodeState, dispatch] = useReducer(NodeReducer, initialState)
  const { state, signOut, setIsError : setAuthError, setIsActionLoading: setAuthActionLoading } = useContext(Context)
  const [isLoading, setIsLoading] = useState(true)
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const httpOptions = {
    headers: {
      Authorization: `Bearer ${state.authToken}`
    }
  }

  const handleError = useCallback(error => {
    if (error.response) {
      if(error.response.status === 403){
        setAuthError('Token Expired! Sign in again.')
        setAuthActionLoading(false)
        signOut()
      }
      setIsError(error.response.data.msg)
    } else if (error.request) {
      setIsError('Something went wrong while trying to contact the server!')
    } else {
      setIsError(error.toString())
    }
    setIsActionLoading(false)
     // eslint-disable-next-line
  }, [])

  const fetchNodes = useCallback(
    async () => {
      try {
        setIsActionLoading(true)
        const nodes = await Axios.get(`${apiUrl}node-library/view`, httpOptions)
        const payload = nodes.data
        dispatch({ type: NODE_TYPES.FETCH_NODES, payload })
        setIsActionLoading(false)
        setIsLoading(false)
      } catch (error) {
        handleError(error)
      }
    }, // eslint-disable-next-line
    [httpOptions]
  )

  const createNode = useCallback(
    async payload => {
      try {
        setIsActionLoading(true)
        await Axios.post(`${apiUrl}node-library/create`, payload, httpOptions)
        setIsActionLoading(false)
        dispatch({ type: NODE_TYPES.CREATE_NODE, payload })
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    [httpOptions]
  )

  const updateNode = useCallback(
    async payload => {
      try {
        setIsActionLoading(true)
        await Axios.post(`${apiUrl}node-library/update`, payload, httpOptions)
        setIsActionLoading(false)
        dispatch({ type: NODE_TYPES.UPDATE_NODE, payload })
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    [httpOptions]
  )

  const deleteNode = useCallback(
    async payload => {
      try {
        setIsActionLoading(true)
        await Axios.post(
          `${apiUrl}node-library/delete`,
          { id: payload.id },
          httpOptions
        )
        dispatch({ type: NODE_TYPES.DELETE_NODE, payload })
        setIsActionLoading(false)
      } catch (error) {
        handleError(error)
      }
    },
    // eslint-disable-next-line
    [httpOptions]
  )

  return (
    <NodeContext.Provider
      value={{
        nodeState,
        fetchNodes,
        createNode,
        deleteNode,
        updateNode,
        isActionLoading,
        isError,
        setIsError,
        isLoading
      }}
    >
      {children}
    </NodeContext.Provider>
  )
}

export const NodeContext = createContext(initialState)

export default NodeContextProvider
