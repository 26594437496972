import React, { useContext, createRef, useState, useEffect } from 'react'
import { Context } from '../../states/AuthContextProvider'
import MaterialTable from 'material-table'
import axios from 'axios'
import { apiUrl } from '../../config/environment'

const columns = [
  {
    title: 'Account',
    tooltip: 'Account Name',
    field: 'account'
  },
  {
    title: 'Reg. TCTR',
    tooltip: 'Regular TCTR Percentage Value',
    field: 'regTctr'
  },
  {
    title: 'Reg. KCTR',
    tooltip: 'Regular KCTR Percentage Value',
    field: 'regKctr'
  },
  {
    title: 'Reg. Factor',
    tooltip: 'Regular Factor Value',
    field: 'regFactor'
  },
  {
    title: 'Ins. TCTR',
    tooltip: 'Instant TCTR Percentage Value',
    field: 'insTctr'
  },
  {
    title: 'Ins. KCTR',
    tooltip: 'Instant KCTR Percentage Value',
    field: 'insKctr'
  },
  {
    title: 'Ins. Factor',
    tooltip: 'Instant Factor Value',
    field: 'insFactor'
  }
]

const MappedCutoffTable = ({ version }) => {
  const { state } = useContext(Context)
  const httpOptions = {
    headers: {
      Authorization: `Bearer ${state.authToken}`
    }
  }
  const mappedTableRef = createRef()
  const [mappedData, setMappedData] = useState([])
  const [triggerHook, setTriggerHook] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    async function fetchData () {
      const response = await axios(
        `${apiUrl}cut-off/view/${version}`,
        httpOptions
      )
      const x = []
      response.data.mapped.map(e =>
        x.push({
          account: e.account,
          regTctr: e.regular.tctrP,
          regKctr: e.regular.kctrP,
          regFactor: e.regular.factor,
          insTctr: e.instant.tctrP,
          insKctr: e.instant.kctrP,
          insFactor: e.instant.factor
        })
      )
      setMappedData(x)
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line
  }, [triggerHook])
  return (
    <>
      <MaterialTable
        tableRef={mappedTableRef}
        title='Mapped Table'
        columns={columns}
        data={mappedData}
        isLoading={isLoading}
        actions={[
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => setTriggerHook(!triggerHook)
          }
        ]}
      />
    </>
  )
}

export default MappedCutoffTable
