import React, { useState } from 'react'
import { Box, Button, makeStyles, TextField } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import clone from 'lodash/clone'
import cloneDeep from 'lodash/cloneDeep'

const useStyles = makeStyles(theme => ({
  root2: {
    flexGrow: 1,
    textAlign: 'left',
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(2)
  },
  formJSON: {
    textAlign: 'left',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  }
}))

const JsonAsForm = ({ setjson, json, allTraversal, handleOpen }) => {
  const classes = useStyles()
  const [btnState, setBtnState] = useState(json.map(e => false))
  const [paramName, setParamName] = useState('')

  const handleOnChange = (target, i, e2) => {
    const copiedValue = JSON.stringify(json[i])
    const reParsedJSON = JSON.parse(copiedValue)
    reParsedJSON[e2] = target.currentTarget.value.toString()
    setjson([...json.filter((e, index) => index !== i), reParsedJSON])
  }

  const handleNewParamClick = (e, i) => {
    const newInput = cloneDeep(json)
    newInput[i][paramName] = 0
    setjson(newInput)
    setBtnState(false)
  }

  const handleBtnArrayState = (tOrF, indexOfSet) => {
    const newBtnState = clone(btnState)
    newBtnState[indexOfSet] = tOrF
    setBtnState(newBtnState)
  }

  const handleNewSetClick = () => {
    const copiedValue = JSON.stringify(json[0])
    const reParsedJSON = JSON.parse(copiedValue)
    setjson([...json, reParsedJSON])
  }

  return (
    <>
      {json.map((e, i) => (
        <Box key={i} className={classes.root2}>
          {Object.keys(e)
            .map((e2, i2) => (
              <TextField
                onChange={target => handleOnChange(target, i, e2)}
                className={classes.formJSON}
                key={i2}
                label={e2}
                defaultValue={e[e2]}
                variant='outlined'
                size='small'
                InputLabelProps={{
                  shrink: true
                }}
              />
            ))}

          {btnState[i] ? (
            <>
              <TextField
                id='outlined-basic'
                label='name'
                name='name'
                variant='outlined'
                fullWidth
                required
                defaultValue={paramName}
                onChange={e => setParamName(e.currentTarget.value)}
                placeholder='Algorithm Name'
                autoFocus
              />
              <Button
                onClick={e => handleNewParamClick(e, i)}
                variant='outlined'
                fullWidth
                color='primary'
              >
                <CheckIcon />
              </Button>
              <Button
                onClick={() => handleBtnArrayState(false, i)}
                variant='outlined'
                fullWidth
                color='secondary'
              >
                <CloseIcon />
              </Button>

            </>
          ) : (
              <>
                <Button
                  onClick={() => handleBtnArrayState(true, i)}
                  fullWidth
                  color='primary'
                  variant='outlined'
                >
                  add new parameter
                </Button>
                <Button
                  onClick={ev => handleOpen({ conf: allTraversal[i], index: i })}
                  variant='outlined'
                  color='secondary'
                  fullWidth
                >
                  Show Output
                </Button>
              </>
            )}
        </Box>
      ))}

      <Button
        onClick={handleNewSetClick}
        variant='outlined'
        fullWidth
        color='secondary'
      >
        add another set
      </Button>
    </>
  )
}

export default JsonAsForm
