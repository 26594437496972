import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Container from '@material-ui/core/Container'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { MainListItems } from './menu-lists/MainListItems'
import { TestListItems } from './menu-lists/TestListItems'
import { DocListItems } from './menu-lists/DocListItems'
import { ExtDepListItems } from './menu-lists/ExtDepListItems'
import { Context } from '../states/AuthContextProvider'
import Dashboard from '../pages/Dashboard'
import CutoffTable from '../pages/CutoffTable'
import NodeLib from '../pages/NodeLib'
import SubTree from '../pages/SubTree'
import TestNode from '../pages/TestNode'
import TestSubtree from '../pages/TestSubtree'
import AlgorithmTree from '../pages/Algorithm'
import TestAlgorithm from '../pages/TestAlgorithm'
import PreCalculation from '../pages/PreCalculation'
import PostCalculation from '../pages/PostCalculation'
import Docs from '../docs/Docs'
import Popover from '@material-ui/core/Popover'
import { Backdrop, CircularProgress, FormControlLabel } from '@material-ui/core'
import { SubTreeContext } from '../states/SubtreeContextProvider'
import { NodeContext } from '../states/NodeContextProvider'
import { AlgorithmContext } from '../states/AlgorithmContextProvider'
import SiteTour from './site-tour/SiteTour'
import ExternalLibrary from '../pages/ExternalLibrary'
import { ExternalContext } from '../states/ExternalContextProvider'
import MSwitch from '@material-ui/core/Switch'
import NightsStayIcon from '@material-ui/icons/NightsStay';
import Scheduling from '../pages/Scheduling'

const routes = [
  {
    path: '/dashboard',
    component: Dashboard
  },
  {
    path: '/cut-off/v3',
    component: CutoffTable,
    version: 'v3'
  },
  {
    path: '/cut-off/v4',
    component: CutoffTable,
    version: 'v4'
  },
  {
    path: '/node-library',
    component: NodeLib,
    version: 'v4'
  },
  {
    path: '/subtree-library',
    component: SubTree
  },
  {
    path: '/algorithm-library',
    component: AlgorithmTree
  },
  {
    path: '/scheduling',
    component: Scheduling
  },
  {
    path: '/test-node',
    component: TestNode
  },
  {
    path: '/test-subtree',
    component: TestSubtree
  },
  {
    path: '/test-algorithm',
    component: TestAlgorithm
  },
  {
    path: '/pre-calculation',
    component: PreCalculation
  },
  {
    path: '/post-calculation',
    component: PostCalculation
  },
  {
    path: '/external-library',
    component: ExternalLibrary
  },
  {
    path: '/docs',
    component: Docs
  }
]
const drawerWidth = 230

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 20
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240
  },
  popover: {
    pointerEvents: 'none'
  },
  ppaper: {
    padding: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

export default function Layout ({ theme, setTheme }) {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const [isTourOpen, setIsTourOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuName, setMenuName] = useState(null)
  const [title, setTitle] = useState('Dashboard')

  const { signOut } = useContext(Context)
  const { isLoading: nodeIsLoading, fetchNodes } = useContext(NodeContext)
  const { isLoading: subIsLoading, fetchSubtrees } = useContext(SubTreeContext)
  const { isLoading: algoIsLoading, fetchAlgorithms } = useContext(
    AlgorithmContext
  )
  const { isLoading: externalIsLoading, fetchExternals } = useContext(
    ExternalContext
  )
  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handlePopoverOpen = (event, name) => {
    if (!open) {
      setMenuName(name)
      setAnchorEl(event.currentTarget)
    }
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const popen = Boolean(anchorEl)

  useEffect(() => {
    async function fetchData () {
      await fetchNodes()
      await fetchSubtrees()
      await fetchAlgorithms()
      await fetchExternals()
    }
    fetchData()
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.root}>
      <Router>
        <CssBaseline />
        <AppBar
          position='absolute'
          className={clsx(classes.appBar, open && classes.appBarShift)}
          elevation={0}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component='h1'
              variant='h6'
              color='inherit'
              noWrap
              className={classes.title}
            >
              {title}
            </Typography>
            <FormControlLabel
              control={
                <MSwitch
                  checked={!theme}
                  onChange={e => setTheme(!theme)}
                  color='primary'
                  name='darkSwitch'
                />
              }
              label={<NightsStayIcon />}
            />

            <IconButton color='inherit' onClick={() => signOut()}>
              <ExitToAppIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Drawer
          variant='permanent'
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <MainListItems
            handlePopoverOpen={handlePopoverOpen}
            handlePopoverClose={handlePopoverClose}
          />
          <Divider />
          <TestListItems
            handlePopoverOpen={handlePopoverOpen}
            handlePopoverClose={handlePopoverClose}
          />
          <Divider />
          <DocListItems
            handlePopoverOpen={handlePopoverOpen}
            handlePopoverClose={handlePopoverClose}
          />
          <Divider />
          <ExtDepListItems
            handlePopoverOpen={handlePopoverOpen}
            handlePopoverClose={handlePopoverClose}
          />
          <Popover
            elevation={1}
            id='mouse-over-popover'
            className={classes.popover}
            classes={{
              paper: classes.ppaper
            }}
            open={popen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left'
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
            children={<Typography>{menuName}</Typography>}
          ></Popover>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth='xl' className={classes.container}>
            <Switch>
              {routes.map((route, i) => (
                <RouteWithSubRoutes
                  key={i}
                  {...route}
                  setTitle={setTitle}
                  setIsTourOpen={setIsTourOpen}
                />
              ))}
            </Switch>
            <SiteTour isTourOpen={isTourOpen} setIsTourOpen={setIsTourOpen} />
          </Container>
        </main>
      </Router>
      <Backdrop
        className={classes.backdrop}
        open={
          nodeIsLoading || subIsLoading || algoIsLoading || externalIsLoading
        }
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  )
}

function RouteWithSubRoutes (route) {
  return (
    <Route
      path={route.path}
      render={props => (
        <route.component
          {...props}
          setTitle={route.setTitle}
          setIsTourOpen={route.setIsTourOpen}
          routes={route.routes}
          version={route.version}
        />
      )}
    />
  )
}
