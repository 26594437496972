import React from 'react'
import MainCutoffTable from '../components/cut-off-tables/MainCutoffTable'
import MapperCutoffTable from '../components/cut-off-tables/MapperCutoffTable'
import MappedCutoffTable from '../components/cut-off-tables/MappedCutoffTable'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'

function SimpleDialog (props) {
  const { onClose, selectedValue, open } = props

  const handleClose = () => {
    onClose(selectedValue)
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
      PaperProps={{
        style: {
          backgroundColor: '#eb3b5a',
          color: 'white'
        }
      }}
    >
      <DialogTitle id='simple-dialog-title'>Important!</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: 'white' }}>
          Changing any values in the cut-off table will directly affect the
          values being used in Brain API production.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default function CutoffTable ({ version, setTitle }) {
  setTitle(`External Dependencies - cut-off table ${version}`)
  const [open, setOpen] = React.useState(true)

  const handleClose = value => {
    setOpen(false)
  }
  return (
    <>
      <MainCutoffTable version={version} />
      <br />
      <MapperCutoffTable version={version} />
      <br />
      <MappedCutoffTable version={version} />
      <SimpleDialog open={open} onClose={handleClose} />
    </>
  )
}
