import cal from './function-library/expressionFunctions'
const evaluate = require('static-eval')
const esprima = require('esprima')

export const prePostCal = async (input, body) => {
  let error = []
  try {
    const externals = {
      modify: function (name, value) {
        body[name] = value

        return body
      },
      allow: function (arr) {
        Object.keys(body).forEach(e => {
          if (!arr.includes(e)) {
            delete body[e]
          }
        })
        return body
      },
      ...cal,
      body: body
    }

    Object.assign(externals, body)

    const supportedFuns = [
      'modify',
      'allow',
      'max',
      'min',
      'avg',
      'abs',
      'search',
      'currentHour',
      'currentDay',
      'between',
      'floor',
      'ceil',
      'round',
      'diffp',
      'zeroify'
    ]
    const eparsed = esprima.parseScript(input, { tolerant: true }, node => {
      if (node.name === 'console') {
        throw new Error('Sorry console statements are not supported!')
      }
      if (node.callee) {
        if (!supportedFuns.includes(node.callee.name)) {
          throw new Error(
            `Sorry, unsupported function call: ${node.callee.name}`
          )
        }
      }
      if (
        node.type === 'VariableDeclarator' ||
        node.type === 'VariableDeclaration'
      ) {
        throw new Error(`Sorry, variable declarations are not supported!`)
      }

      if (node.type === 'BinaryExpression') {
        if (node.left.type === 'Identifier') {
          if (!externals.hasOwnProperty(node.left.name)) {
            externals[node.left.name] = 0
          }
        }
        if (node.right.type === 'Identifier') {
          if (!externals.hasOwnProperty(node.right.name)) {
            externals[node.right.name] = 0
          }
        }
      }

      if (node.type === 'ConditionalExpression') {
        if (node.alternate.type === 'Identifier') {
          if (!externals.hasOwnProperty(node.alternate.name)) {
            externals[node.alternate.name] = 0
          }
        }
        if (node.consequent.type === 'Identifier') {
          if (!externals.hasOwnProperty(node.consequent.name)) {
            externals[node.consequent.name] = 0
          }
        }
      }
      
    })
    error = eparsed.errors
    const ast = eparsed.body[0].expression
    await evaluate(ast, externals)
  } catch (err) {
    error.push(err.toString())
  }

  return { value: body, error }
}
