export const SUBTREE_TYPES = {
  FETCH_SUBTREES: 'FETCH_SUBTREES',
  CREATE_SUBTREE: 'CREATE_SUBTREE',
  EDIT_SUBTREE: 'EDIT_SUBTREE',
  DELETE_SUBTREE: 'DELETE_SUBTREE'
}

const SubtreeReducer = (state, action) => {
  switch (action.type) {
    case SUBTREE_TYPES.FETCH_SUBTREES:
      return {
        ...state,
        subtrees: action.payload
      }
    case SUBTREE_TYPES.CREATE_SUBTREE:
      return {
        ...state,
        subtrees: [...state.subtrees, action.payload]
      }
    case SUBTREE_TYPES.EDIT_SUBTREE:
      return {
        ...state,
        subtrees: [
          ...state.subtrees.filter(e => e.id !== action.payload.id),
          action.payload
        ]
      }
    case SUBTREE_TYPES.DELETE_SUBTREE:
      return {
        ...state,
        subtrees: [...state.subtrees.filter(e => e.id !== action.payload.id)]
      }
    default:
      return state
  }
}

export default SubtreeReducer
