import Axios from 'axios'
import { apiUrl } from '../config/environment'

export const cutoffFetcher = async () => {
  let cTable = []
  const authToken = JSON.parse(localStorage.getItem('state')).authToken
  try {
    cTable = await Axios(`${apiUrl}cut-off/view/v3`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
  } catch (error) {
    cTable = []
  }
  return cTable
}

export const cutoffFinder = (cTable, toFind) => {
  let result = null
  const matchedCutoff = cTable.data.mapped.find(
    e => e.account === toFind.adnet_campaign_category_name
  )

  if (matchedCutoff) {
    if (toFind.facebook_campaign_name.includes('Instant')) {
      result = matchedCutoff.instant
    } else {
      result = matchedCutoff.regular
    }
  }

  return result
}
