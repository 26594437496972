import cloneDeep from 'lodash/cloneDeep'
import { v4 } from 'uuid'

export const expandConfiguration = (
  chartData,
  subtreeState,
  algorithmState,
  nodeState
) => {
  const nodesFromChart = cloneDeep(Object.values(chartData.nodes))
  const linksFromChart = cloneDeep(Object.values(chartData.links))
  const expandedNodes = []
  const expandedLinks = []
  let error = null
  try {
    nodesFromChart.forEach((nodeChart, nodeChartIndex) => {
      const found = nodeState.nodes.find(
        node => node.id === nodeChart.properties.custom
      )
      if (!found) {
        const linkToAlter = linksFromChart.filter(
          e => e.to.nodeId === nodeChart.id
        )
        const checkTail = linksFromChart.find(
          e => e.from.nodeId === nodeChart.id
        )
        if (!linkToAlter.length || checkTail) {
          throw Error('sub-tree can only be applied as a leaf node!')
        }

        const subtree = subtreeState.subtrees.find(
          subtree => subtree.id === nodeChart.properties.custom
        )
        const algorithm = algorithmState.algorithms.find(
          al => al.id === nodeChart.properties.custom
        )
        if (subtree) {
          const alteredLinks = cloneDeep(Object.values(subtree.conf.links))
          Object.values(subtree.conf.nodes).forEach(node => {
            let rootFlag = 1
            const altered = cloneDeep(node)
            altered.id = v4()
            altered.position = {
              x: node.position.x + nodeChart.position.x,
              y: node.position.y + nodeChart.position.y
            }
            expandedNodes.push(altered)
            Object.values(subtree.conf.links).forEach((link, index) => {
              if (link.from.nodeId === node.id) {
                alteredLinks[index].from.nodeId = altered.id
              }
              if (link.to.nodeId === node.id) {
                rootFlag = 0
                alteredLinks[index].to.nodeId = altered.id
              }
            })
            if (rootFlag) {
              linkToAlter.forEach(toAlter => {
                toAlter.to.nodeId = altered.id
              })
            }
          })
          alteredLinks.forEach(e => {
            const copied = cloneDeep(e)
            copied.id = v4()
            expandedLinks.push(copied)
          })
        }
        if (algorithm) {
          const alteredLinks = cloneDeep(Object.values(algorithm.conf.links))
          Object.values(algorithm.conf.nodes).forEach(node => {
            let rootFlag = 1
            const altered = cloneDeep(node)
            altered.id = v4()
            altered.position = {
              x: node.position.x + nodeChart.position.x,
              y: node.position.y + nodeChart.position.y
            }
            expandedNodes.push(altered)
            Object.values(algorithm.conf.links).forEach((link, index) => {
              if (link.from.nodeId === node.id) {
                alteredLinks[index].from.nodeId = altered.id
              }
              if (link.to.nodeId === node.id) {
                rootFlag = 0
                alteredLinks[index].to.nodeId = altered.id
              }
            })
            if (rootFlag) {
              linkToAlter.forEach(toAlter => {
                toAlter.to.nodeId = altered.id
              })
            }
          })
          alteredLinks.forEach(e => {
            const copied = cloneDeep(e)
            copied.id = v4()
            expandedLinks.push(copied)
          })
        }
        delete nodesFromChart[nodeChartIndex]
      }
    })
  } catch (err) {
    console.log(err.toString())
    error = err.toString()
  }

  const finalNodes = {}
  const finalLinks = {}
  nodesFromChart.concat(expandedNodes).forEach(e => (finalNodes[e.id] = e))
  linksFromChart.concat(expandedLinks).forEach(e => (finalLinks[e.id] = e))

  return { nodes: finalNodes, links: finalLinks, error }
}
