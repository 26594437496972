import { Button, ButtonGroup } from '@material-ui/core'
import React from 'react'

const CanvasZoomButtons = ({
  flowchartRef,
  setChartData,
  refreshCanvas,
  chartData
}) => {
  return (
    <ButtonGroup
      size="small"
      variant='outlined'
      aria-label='outlined primary button group'
    >
      <Button
        onClick={() => {
          const newScale = flowchartRef.current.state.scale + 0.1
          setChartData({
            ...chartData,
            scale: newScale > 0.9 ? 0.9 : newScale
          })
          refreshCanvas()
        }}
        variant='outlined'
        size='small'
      >
        Zoom In
      </Button>
      <Button
        onClick={() => {
          setChartData({
            ...chartData,
            scale: flowchartRef.current.state.scale - 0.1
          })
          refreshCanvas()
        }}
        variant='outlined'
        size='small'
      >
        Zoom Out
      </Button>
    </ButtonGroup>
  )
}

export default CanvasZoomButtons
