import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import TableChartIcon from '@material-ui/icons/TableChart'
import List from '@material-ui/core/List'
import { Link } from 'react-router-dom'

export const ExtDepListItems = ({ handlePopoverClose, handlePopoverOpen }) => {
  return (
    <List>
      <ListSubheader inset>Cut-Off Tables</ListSubheader>
      <Link
        style={{ textDecoration: 'none', color: 'inherit' }}
        to='/cut-off/v3'
      >
        <ListItem
          button
          onMouseEnter={e => handlePopoverOpen(e, 'Matrix V3.X Cut-off Table')}
          onMouseLeave={handlePopoverClose}
        >
          <ListItemIcon>
            <TableChartIcon />
          </ListItemIcon>
          <ListItemText primary='Matrix V3.X' />
        </ListItem>
      </Link>
      <Link
        style={{ textDecoration: 'none', color: 'inherit' }}
        to='/cut-off/v4'
      >
        <ListItem
          button
          onMouseEnter={e => handlePopoverOpen(e, 'Matrix V4.X Cut-off Table')}
          onMouseLeave={handlePopoverClose}
        >
          <ListItemIcon>
            <TableChartIcon />
          </ListItemIcon>
          <ListItemText primary='Matrix V4.X' />
        </ListItem>
      </Link>
    </List>
  )
}
