import React, {
  useContext,
  createRef,
  useCallback,
  useState,
  useRef
} from 'react'
import MaterialTable from 'material-table'
import { NodeContext } from '../states/NodeContextProvider'
import AvailListDialog from '../components/AvailListDialog'
import { TextField } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { AlertTitle } from '@material-ui/lab'
import { Link } from 'react-router-dom'

import CloneDialog from '../components/fragments/CloneDialog'

const columns = [
  {
    title: 'ID',
    tooltip: 'Node ID',
    field: 'id',
    editable: 'always',
    defaultSort: 'asc'
  },
  {
    title: 'Group ID',
    tooltip: 'group name',
    field: 'groupID'
  },
  {
    title: 'Description',
    tooltip: 'Describe Expression',
    field: 'description'
  },
  {
    title: 'Expression',
    tooltip: 'Leaf/Non-leaf Node Expression',
    field: 'expression',
    editComponent: props => (
      <TextField
        fullWidth
        multiline
        type='text'
        placeholder='Expression'
        defaultValue={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    )
  }
]

const NodeLibrary = ({ setTitle }) => {
  setTitle('Node Library')
  const mainTableRef = createRef()
  const {
    nodeState,
    deleteNode,
    isActionLoading,
    fetchNodes,
    createNode,
    updateNode,
    isError,
    setIsError
  } = useContext(NodeContext)
  const [initialFormData, setInitialFormData] = useState({})
  const onRowAdd = useCallback(
    async data => {
      await createNode(data)
    },
    //eslint-disable-next-line
    [nodeState]
  )

  const onRowDelete = useCallback(
    async data => {
      await deleteNode(data)
    },
    //eslint-disable-next-line
    [nodeState]
  )

  const onRowUpdate = useCallback(
    async (newData, oldData) => {
      await updateNode({ newData, oldData })
    },
    //eslint-disable-next-line
    [nodeState]
  )

  const handleRefresh = async () => {
    await fetchNodes()
  }

  const viewRef = useRef(null)
  const executeScroll = () =>
    viewRef.current.scrollIntoView({ behavior: 'smooth' })
  const [cloneDialog, setcloneDialog] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  return (
    <>
      <Alert ref={viewRef} severity='info' style={{ marginBottom: '15px' }}>
        Nodes are logical expressions that define leaf/non-leaf conditions.{' '}
        <Link style={{ textDecoration: 'none' }} to='/docs/node'>
          <strong> Learn more </strong>
        </Link>
      </Alert>

      {isError && (
        <Alert
          variant='outlined'
          severity='error'
          onClose={() => {
            setIsError(false)
          }}
        >
          <AlertTitle>Error</AlertTitle>
          {isError}
        </Alert>
      )}
      <AvailListDialog nodeLib />
      <MaterialTable
        tableRef={mainTableRef}
        title='Nodes'
        columns={columns}
        data={nodeState.nodes}
        isLoading={isActionLoading}
        initialFormData={initialFormData}
        editable={{
          onRowAdd: onRowAdd,
          onRowUpdate: onRowUpdate,
          onRowDelete: onRowDelete
        }}
        actions={[
          {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: handleRefresh
          },
          {
            icon: 'library_add',
            tooltip: 'Clone Node',
            position: 'row',
            onClick: (event, rowData) => {
              const materialTable = mainTableRef.current
              executeScroll()
              setInitialFormData({
                ...rowData,
                id: null
              })
              materialTable.dataManager.changeRowEditing()
              materialTable.setState({
                ...materialTable.dataManager.getRenderState(),
                showAddRow: true
              })
            }
          },
          {
            tooltip: 'Clone All Nodes',
            icon: 'library_add',
            onClick: (evt, data) => {
              setcloneDialog(true)
              console.log(data)
              setSelectedRows(data)
            }
          }
        ]}
        options={{
          actionsColumnIndex: -1,
          paging: true,
          pageSize: 20,
          emptyRowsWhenPaging: true,
          pageSizeOptions: [5, 10, 20, 50],
          sorting: true,
          addRowPosition: 'first',
          grouping: true,
          selection: true
        }}
      />
      <CloneDialog
        open={cloneDialog}
        setOpen={setcloneDialog}
        selectedRows={selectedRows}
      />
    </>
  )
}

export default NodeLibrary
