import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { importMDX } from 'mdx.macro'
import { Paper } from '@material-ui/core'
const GettingStarted = importMDX.sync('./getting-started.mdx')
const Node = importMDX.sync('./node.mdx')
const Subtree = importMDX.sync('./subtree.mdx')
const Algorithm = importMDX.sync('./algorithm.mdx')
const PreCalculation = importMDX.sync('./pre-calculation.mdx')
const PostCalculation = importMDX.sync('./post-calculation.mdx')
const ExternalDependency = importMDX.sync('./external-dependency.mdx')
const Testing = importMDX.sync('./testing.mdx')
const Scheduling = importMDX.sync('./scheduling.mdx')

const Docs = ({ setTitle }) => {
  setTitle('Documentation')
  let { path } = useRouteMatch()
  return (
    <Paper
      elevation={1}
      variant='outlined'
      style={{ marginTop: '15px', padding: '35px' }}
    >
      <Switch>
        <Route path={`${path}/getting-started`}>
          <GettingStarted />
        </Route>
        <Route path={`${path}/node`}>
          <Node />
        </Route>
        <Route path={`${path}/subtree`}>
          <Subtree />
        </Route>
        <Route path={`${path}/algorithm`}>
          <Algorithm />
        </Route>
        <Route path={`${path}/pre-calculation`}>
          <PreCalculation />
        </Route>
        <Route path={`${path}/post-calculation`}>
          <PostCalculation />
        </Route>
        <Route path={`${path}/external-dependency`}>
          <ExternalDependency />
        </Route>
        <Route path={`${path}/testing`}>
          <Testing />
        </Route>
        <Route path={`${path}/scheduling`}>
          <Scheduling />
        </Route>
      </Switch>
    </Paper>
  )
}
export default Docs
