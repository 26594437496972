import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import React from 'react'

const DeleteDialog = ({ open, selected, onClose }) => {
  return (
    <Dialog
      //   onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: '#eb3b5a',
          color: 'white'
        }
      }}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <DialogTitle id='simple-dialog-title'>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: 'white' }}>
          This will delete the saved data <strong>"{selected}"</strong> and all
          of it's content.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => onClose(true)}>YES</Button>
        <Button variant='outlined' onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog
