import React, { useContext, useState, useCallback } from 'react'
import {
  Paper,
  Button,
  Typography,
  Grid,
  makeStyles,
  FormControlLabel,
  Switch,
  Box,
  TextField
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { evaluateNode } from '../misc/nodeEval'
import { paramListDefault } from '../misc/paramList'
import { NodeContext } from '../states/NodeContextProvider'
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab'
import JSONInput from 'react-json-editor-ajrm/index'
import themes from 'react-json-editor-ajrm/themes'
import locale from 'react-json-editor-ajrm/locale/en'
import JsonAsFormSingle from '../components/fragments/JsonAsFormSingle'
import { Link } from 'react-router-dom'
import { ExternalContext } from '../states/ExternalContextProvider'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}))
const TestNode = ({ setTitle }) => {
  setTitle('Test Node')
  const classes = useStyles()
  const { nodeState } = useContext(NodeContext)

  const { handleSubmit, control } = useForm()
  const [result, setResult] = useState('press test')
  const [isError, setIsError] = useState(null)
  const [jsonInput, setJsonInput] = useState(paramListDefault)
  const [selectedEx, setSelectedEx] = useState('select a node first')
  const [switchState, setSwitchState] = useState(false)
  const { externalState } = useContext(ExternalContext)
  const allExternals = externalState.externals.map(e => e.id)

  const onSubmit = useCallback(
    async data => {
      setIsError(null)
      const nodeExpression = selectedEx
      try {
        const copiedData = JSON.parse(JSON.stringify(jsonInput))
        const evaluatedNode = await evaluateNode(
          nodeExpression,
          copiedData,
          allExternals
        )
        setResult(evaluatedNode.value + '')
        if (evaluatedNode.error.length > 0) {
          setIsError(evaluatedNode.error)
        }
      } catch (error) {
        setIsError(`Oops! something went wrong. please check configuration.`)
      }
    },
    [selectedEx, jsonInput, allExternals]
  )

  const getOpObj = option => {
    let node = nodeState.nodes.find(e => e.id === option)
    if (!node) {
      node = nodeState.nodes[0]
    }
    setSelectedEx(node.expression)
    return node.id
  }

  return (
    <>
      <Alert severity='info' style={{ marginBottom: '15px' }}>
        We can test nodes by selecting from the dropdown
        <Link style={{ textDecoration: 'none' }} to='/docs/testing'>
          <strong> Learn more </strong>
        </Link>
      </Alert>

      {isError && (
        <div className={classes.alert}>
          <Alert severity='error'>
            <AlertTitle>Error</AlertTitle>
            <strong>{isError}</strong>
          </Alert>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} className={classes.paper}>
              <Box display='flex' flexDirection='row-reverse'>
                <FormControlLabel
                  control={
                    <Switch
                      checked={switchState}
                      onChange={() => setSwitchState(!switchState)}
                      name='useJSON'
                      color='primary'
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label='JSON'
                />
              </Box>
              {switchState ? (
                <JSONInput
                  onChange={e => setJsonInput(JSON.parse(e.json))}
                  id='a_unique_id'
                  placeholder={jsonInput}
                  locale={locale}
                  colors={themes.dark_vscode_tribute}
                  height='100%'
                  width='100%'
                  style={{
                    body: {
                      fontSize: '18px'
                    }
                  }}
                />
              ) : (
                <JsonAsFormSingle setjson={setJsonInput} json={jsonInput} />
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} className={classes.paper}>
              <Controller
                as={
                  <Autocomplete
                    fullWidth
                    options={nodeState.nodes.map(e => e.id)}
                    getOptionLabel={option => getOpObj(option)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Node ID'
                        variant='outlined'
                      />
                    )}
                  />
                }
                name='nodeID'
                control={control}
                defaultValue={nodeState.nodes[0]}
                onChange={([, obj]) => getOpObj(obj)}
              />

              <Typography
                variant='h6'
                component='h6'
                style={{ overflowX: 'auto' }}
              >
                {selectedEx}
              </Typography>
              <Button
                style={{ marginTop: '15px' }}
                fullWidth
                size='large'
                color='primary'
                variant='outlined'
                type='submit'
              >
                TEST
              </Button>
              <Typography
                variant='h5'
                component='h5'
                color='secondary'
                style={{ textAlign: 'center', marginTop: '8px' }}
                gutterBottom
              >
                {result}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default TestNode
