import React, { useRef, useContext, useState, useEffect } from 'react'

import {
  Content,
  Page,
  Sidebar,
  SidebarItem,
  Message,
  NodeInnerCustom,
  NodeInnerCustomReverse,
  PortCustom
} from '../components/flowchart'
import DeleteIcon from '@material-ui/icons/Delete'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import { data } from '../misc/exampleTreeData'
import { portConf } from '../misc/portConfiguration'

import {
  makeStyles,
  Box,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  ListItemText,
  ListItem,
  FormControlLabel,
  Switch
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { FlowChartWithState } from '@mrblenny/react-flow-chart'
import { SubTreeContext } from '../states/SubtreeContextProvider'
import { NodeContext } from '../states/NodeContextProvider'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Link, Prompt } from 'react-router-dom'
import CanvasZoomButtons from '../components/fragments/CanvasZoomButtons'
import DeleteDialog from '../components/fragments/DeleteDialog'
import cloneDeep from 'lodash/cloneDeep'

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  box: {
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 0,
    paddingRight: 0
  },
  back: {
    background: 'linear-gradient(to right, #00b4db, #0083b0)',
    marginLeft: 0,
    marginRight: 0,
    color: 'white'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  chip: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  linkStyle: {
    textDecoration: 'none',
    color: 'black'
  },
  setButtons: {
    textTransform: 'none'
  },
    draftBorder: {
    border: 'solid 2px red'
  }
}))

const SubTree = ({ setTitle }) => {
  setTitle('Sub-Tree Library')
  const classes = useStyles()
  const flowchartRef = useRef(null)
  const copiedData = cloneDeep(data)
  const [chartData, setChartData] = useState(copiedData)
  const [canvasKey, setCanvasKey] = useState(1)
  const [isEdit, setIsEdit] = useState(false)
  const [isExpression, setIsExpression] = React.useState(false)

  const {
    subtreeState,
    createSubtree,
    editSubtree,
    deleteSubtree,
    isActionLoading,
    isError,
    setIsError
  } = useContext(SubTreeContext)

  const { nodeState } = useContext(NodeContext)
  const { handleSubmit, setValue, getValues, control } = useForm()
  const [searchTerm, setSearchTerm] = React.useState('')
  const [searchResults, setSearchResults] = React.useState([])
  const [sortOrder, setSortOrder] = React.useState(true)
  const [openDel, setOpenDel] = useState(false)
  const [changeTrigger, setChangeTrigger] = useState(false)
  const [isDraft, setIsDraft] = useState(false)
  const [loadedConf, setLoadedConf] = useState({})

  const handleChange = (event: any) => {
    setSearchTerm(event.target.value)
  }
  useEffect(() => {
    let results = []
    if (sortOrder) {
      results = nodeState.nodes
        .filter(e =>
          e.id.toLowerCase().includes(searchTerm.trim().toLowerCase())
        )
        .sort((a, b) =>
          a.id
            .trim()
            .toLowerCase()
            .localeCompare(b.id.trim().toLowerCase())
        )
    } else {
      results = nodeState.nodes
        .filter(e =>
          e.id.toLowerCase().includes(searchTerm.trim().toLowerCase())
        )
        .sort((a, b) =>
          a.id
            .trim()
            .toLowerCase()
            .localeCompare(b.id.trim().toLowerCase())
        )
        .reverse()
    }
    setSearchResults(results)
  }, [searchTerm, sortOrder, nodeState.nodes])

  const onSubmit = async data => {
    try {
      if (isEdit) {
        await editSubtree({
          id: data.id,
          description: data.description,
          conf: flowchartRef.current.state
        })
        cancelEdit()
      } else {
        await createSubtree({
          id: data.id,
          description: data.description,
          conf: flowchartRef.current.state
        })
        cancelEdit()
      }
    } catch (error) {
      console.log(error.response.data)
    }
  }

  const refreshCanvas = () => setCanvasKey(canvasKey + 1)
  const handleSubTreeClick = e => {
    if (isEdit || isDraft) {
      alert('You have unsaved changes, save or discard the progress first.')
    } else {
      const copied = cloneDeep(e.conf)
      const copiedLoad = cloneDeep(e.conf)
      setChartData(copied)
      setLoadedConf(copiedLoad)
      setValue('id', e.id)
      setValue('description', e.description)
      setIsEdit(true)
      refreshCanvas()
    }
  }
  const handleDeleteClick = async () => {
    try {
      await deleteSubtree({
        id: getValues('id')
      })
      cancelEdit()
    } catch (error) {
      console.log(error.response.data)
    }
  }
  const cancelEdit = () => {
    const copied = cloneDeep(data)
    setChartData(copied)
    setValue('id', '')
    setValue('description', '')
    setIsEdit(false)
    setIsDraft(false)
    refreshCanvas()
  }

  const handleClose = confirmed => {
    setOpenDel(false)
    if (confirmed) {
      handleDeleteClick()
    }
  }

  useEffect(() => {
    if (isEdit) {
      if (JSON.stringify(loadedConf) !== JSON.stringify(chartData)) {
        setIsDraft(true)
      }
    } else {
      if (JSON.stringify(data) !== JSON.stringify(chartData)) {
        setIsDraft(true)
      }
    }
    // eslint-disable-next-line
  }, [changeTrigger])

  return (
    <>
      <Alert severity='info' style={{ marginBottom: '15px' }}>
        Subtrees are made up using nodes, they can be embedded inside
        algorithms. One Subtree can be added into multiple algorithms.{' '}
        <Link style={{ textDecoration: 'none' }} to='/docs/subtree'>
          <strong> Learn more </strong>
        </Link>
      </Alert>

      <Prompt
        when={isEdit || isDraft}
        message='You have unsaved changes, are you sure you want to leave?'
      />

      {isDraft && (
        <Alert severity='warning'>
          You are in Draft Mode. Please save or discard any changes.
        </Alert>
      )}

      {isError && (
        <Alert
          variant='outlined'
          severity='error'
          onClose={() => {
            setIsError(false)
          }}
        >
          <AlertTitle>Error</AlertTitle>
          {isError}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='sub-add'>
          <Box
            display='flex'
            flexDirection='row-reverse'
            p={1}
            m={1}
            className={classes.box}
          >
            {isEdit && (
              <>
                <Button
                  style={{ marginLeft: '5px' }}
                  color='secondary'
                  variant='contained'
                  aria-label='delete'
                  onClick={() => setOpenDel(true)}
                  startIcon={<DeleteIcon />}
                  disableElevation
                >
                  Delete
                </Button>
                <Button
                  style={{ marginLeft: '5px' }}
                  color='secondary'
                  variant='outlined'
                  aria-label='add'
                  onClick={cancelEdit}
                >
                  Cancel Edit
                </Button>
              </>
            )}
            {(isDraft || isEdit) && (
              <Button
                color='primary'
                aria-label='add'
                className={classes.back}
                type='submit'
              >
                Save
              </Button>
            )}

            {isDraft && !isEdit && (
              <Button
                style={{ marginRight: '5px' }}
                color='secondary'
                variant='outlined'
                aria-label='add'
                onClick={cancelEdit}
              >
                Discard
              </Button>
            )}
          </Box>
        </div>

        <Paper elevation={1} className='sub-paper'>
          <Box display='flex' p={1} m={1} className={classes.chip}>
            {subtreeState.subtrees.length > 0 ? (
              subtreeState.subtrees.map((e, i) => (
                <Button
                  key={i}
                  variant='outlined'
                  color='primary'
                  className={classes.setButtons}
                  onClick={() => handleSubTreeClick(e)}
                >
                  {e.id}
                </Button>
              ))
            ) : (
              <p>No Data Found</p>
            )}
          </Box>
        </Paper>

        <Paper elevation={3} className={isDraft ? classes.draftBorder : null}>
          <Page>
            <Content>
              {isExpression ? (
                <FlowChartWithState
                  ref={flowchartRef}
                  key={canvasKey}
                  initialValue={chartData}
                  Components={{
                    NodeInner: NodeInnerCustomReverse,
                    Port: PortCustom
                  }}
                  config={{
                    zoom: {
                      maxScale: 0.9,
                      minScale: 0.1
                    }
                  }}
                />
              ) : (
                <FlowChartWithState
                  ref={flowchartRef}
                  key={canvasKey}
                  initialValue={chartData}
                  Components={{
                    NodeInner: NodeInnerCustom,
                    Port: PortCustom
                  }}
                  config={{
                    zoom: {
                      maxScale: 0.9,
                      minScale: 0.1
                    }
                  }}
                />
              )}
            </Content>
            <Sidebar>
              <div style={{ padding: '5px' }} className='sub-input'>
                <Controller
                  as={
                    <TextField
                      id='outlined-basic'
                      label='ID'
                      variant='outlined'
                      fullWidth
                      required
                      disabled={isEdit}
                      placeholder='Sub-Tree ID'
                      autoFocus
                    />
                  }
                  onChange={e => {
                    setIsDraft(true)
                    return e[0].target.value
                  }}
                  name='id'
                  control={control}
                  defaultValue=''
                />
                <Controller
                  as={
                    <TextField
                      id='outlined-basic'
                      label='Description'
                      variant='outlined'
                      fullWidth
                      required
                      multiline
                      style={{ marginTop: '5px' }}
                      placeholder='Sub-Tree Description'
                    />
                  }
                  onChange={e => {
                    setIsDraft(true)
                    return e[0].target.value
                  }}
                  name='description'
                  control={control}
                  defaultValue=''
                />
              </div>

              <Message>
                Drag a node to the left hand side to create a decision tree.
              </Message>

              <Box display='flex' flexDirection='row-reverse' p={1} m={1}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isExpression}
                      onChange={() => setIsExpression(!isExpression)}
                      name='sort'
                      color='primary'
                    />
                  }
                  label={<p style={{fontSize:'12px'}}>Expression View</p>}
                  labelPlacement='start'
                />

                <CanvasZoomButtons
                  flowchartRef={flowchartRef}
                  setChartData={setChartData}
                  refreshCanvas={refreshCanvas}
                  chartData={chartData}
                />
              </Box>
              <List
                className='sub-drop'
                style={{
                  maxHeight: '500px',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  padding: '24px'
                }}
              >
                <Box display='flex'>
                  <TextField
                    label='SEARCH NODES'
                    fullWidth
                    variant='outlined'
                    value={searchTerm}
                    onChange={handleChange}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={sortOrder}
                        onChange={() => setSortOrder(!sortOrder)}
                        name='sort'
                        color='primary'
                      />
                    }
                    label='ASC'
                    labelPlacement='start'
                  />
                </Box>

                {searchResults.length > 0 ? (
                  searchResults.map((e, i) => (
                    <>
                      <div key={i}>
                        <SidebarItem
                          type='input-output'
                          ports={portConf}
                          properties={{
                            custom: e.id
                          }}
                          setChangeTrigger={setChangeTrigger}
                        />
                      </div>
                    </>
                  ))
                ) : (
                  <Link className={classes.linkStyle} to='/node-library'>
                    <ListItem button>
                      <ListItemText primary='Create Nodes' />
                    </ListItem>
                  </Link>
                )}
              </List>
            </Sidebar>
          </Page>
        </Paper>
      </form>
      <DeleteDialog
        open={openDel}
        selected={getValues('id')}
        onClose={handleClose}
      />
      <Backdrop className={classes.backdrop} open={isActionLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  )
}
export default SubTree
