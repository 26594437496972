import React, { useContext } from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'

import Typography from '@material-ui/core/Typography'
import { Context } from '../states/AuthContextProvider'
import { Button, Grid, Paper } from '@material-ui/core'
import WAnimation from '../components/svgs/WAnimation'
import { Link } from 'react-router-dom'

export default function Dashboard ({ setTitle, setIsTourOpen }) {
  setTitle('Dashboard')
  const { state } = useContext(Context)

  return (
    <>
      <CssBaseline />

      <Paper
        elevation={1}
        variant='outlined'
        style={{ marginTop: '15px', padding: '35px' }}
        className='dash-card'
      >
        <Typography component='h1' variant='h4' align='center'>
          Adnet Brain Management
        </Typography>
        <br />
        <Typography align='center'>{state.username}</Typography>

        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justify='center'
        >
          <Grid item xs={3}>
            <WAnimation style={{ height: '400px' }} />
          </Grid>
          <Grid item>
            <Button
              color='secondary'
              variant='outlined'
              style={{ textDecoration: 'none', marginRight: '5px' }}
              onClick={() => setIsTourOpen(true)}
            >
              Start Tour
            </Button>

            <Link to='/docs/getting-started' style={{ textDecoration: 'none' }}>
              <Button
                color='primary'
                variant='outlined'
                className='getting-started'
              >
                Read the docs
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
