import { evaluateNode } from './nodeEval'
import { prePostCal } from './prePostCal'
import { cutoffFetcher, cutoffFinder } from './cutoffFinder'

const keySort = property => {
  let sortOrder = 1

  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }

  return function (a, b) {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property])
    } else {
      return a[property].localeCompare(b[property])
    }
  }
}
const childrenFinder = (parentId, cLinks) => {
  const children = []
  for (const e of Object.entries(cLinks)) {
    if (e[1].from.nodeId === parentId) {
      if (e[1].from.portId === 'port1') {
        children[0] = e[1].to.nodeId
      } else {
        children[1] = e[1].to.nodeId
      }
    }
  }
  return children
}

const confTransformerAl = (
  algorithmConf,
  nodeList,
  subtreeList,
  algorithmList,
  passedKey
) => {
  let index = 0
  const nodes = []
  for (const [key, value] of Object.entries(algorithmConf.conf.nodes)) {
    const notRoot = Object.values(algorithmConf.conf.links)
      .map(e => e.to.nodeId)
      .find(e => e === key)
    let passedKeyOV = key
    if (passedKey !== 0 && index === 0) {
      passedKeyOV = passedKey
    }
    const storedNodeString = nodeList.find(
      e => e.id === value.properties.custom
    )
    const findSub = subtreeList.find(e => e.id === value.properties.custom)
    const algorithmInnerConf = algorithmList.find(
      e => e.id === value.properties.custom
    )
    if (storedNodeString) {
      const nodeJSON = storedNodeString
      const children = childrenFinder(key, algorithmConf.conf.links)
      nodes.push({
        id: passedKeyOV,
        name: value.properties.custom,
        expression: nodeJSON.expression,
        isRoot: !notRoot,
        isLeaf: children.length === 0,
        hasPre: false,
        hasPost: false,
        children
      })
    } else if (findSub) {
      let subIndex = 0
      for (const [subKey, subValue] of Object.entries(findSub.conf.nodes)) {
        const nodeJSON = nodeList.find(e => e.id === subValue.properties.custom)
        const children = childrenFinder(subKey, findSub.conf.links)
        nodes.push({
          id: subIndex === 0 ? key : subKey,
          name: subValue.properties.custom,
          expression: nodeJSON.expression,
          isRoot: index === 0,
          isLeaf: children.length === 0,
          hasPre: false,
          hasPost: false,
          children
        })
        subIndex++
      }
    } else if (algorithmInnerConf) {
      const extractNodes = confTransformerAl(
        algorithmInnerConf,
        nodeList,
        subtreeList,
        algorithmList,
        key
      ).map(e => {
        let postPreAttached = e
        if (postPreAttached.isRoot) {
          postPreAttached.hasPre = true
          postPreAttached.preCal = algorithmInnerConf.preCal
        }
        if (postPreAttached.isLeaf) {
          postPreAttached.hasPost = true
          postPreAttached.postCal = algorithmInnerConf.postCal
        }
        return postPreAttached
      })
      nodes.push(...extractNodes)
    } else {
      console.log('not found')
    }
    index++
  }
  return nodes
}

export const algorithmEval = async (
  algorithmString,
  nodeList,
  subtreeList,
  body,
  algorithmList,
  allExternals
) => {
  const responseArr = []
  const error = []
  algorithmString.preCal.sort(keySort('id'))
  algorithmString.postCal.sort(keySort('id'))
  const cTable = await cutoffFetcher()
  for await (let e of Object.values(body)) {
    e['cutoff'] = await cutoffFinder(cTable, e)

    for await (const preCal of algorithmString.preCal) {
      const prePostRet = await prePostCal(preCal.expression, e)
      e = prePostRet.value
    }

    const transFormedConf = confTransformerAl(
      algorithmString,
      nodeList,
      subtreeList,
      algorithmList,
      0
    )

    let currentNode = transFormedConf.find(e => e.isRoot === true)

    const childrenFinder = (arr, id) => arr.find(e => e.id === id)

    const traversal = []
    while (true) {
      if (currentNode.hasPre) {
        for await (const preCal of currentNode.preCal) {
          const prePostRet = await prePostCal(preCal.expression, e)
          e = prePostRet.value
        }
      }
      const evaluatedNode = await evaluateNode(currentNode.expression, e, allExternals)
      const value = evaluatedNode.value
      error.push(...evaluatedNode.error)
      traversal.push(...[currentNode.name, value])
      if (currentNode.isLeaf === true) {
        e['bid'] = value
        e['display_message'] = traversal
        e['leaf_id'] = traversal[traversal.length - 2]
        if (currentNode.hasPost) {
          for await (const postCal of currentNode.postCal) {
            const prePostRet = await prePostCal(postCal.expression, e)
            e = prePostRet.value
          }
        }
        break
      } else {
        if (value) {
          currentNode = childrenFinder(transFormedConf, currentNode.children[0])
        } else {
          currentNode = childrenFinder(transFormedConf, currentNode.children[1])
        }
      }
    }

    for await (const postCal of algorithmString.postCal) {
      const prePostRet = await prePostCal(postCal.expression, e)
      e = prePostRet.value
    }

    responseArr.push(e)
  }

  return { value: responseArr, error }
}
