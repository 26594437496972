import React, { useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { Box, Chip, makeStyles, Snackbar } from '@material-ui/core'

import {
  paramListForNodes,
  functionList,
  operatorList,
  prePosFuntList,
  paramListOnlyPostCal
} from '../misc/paramList'
const useStyles = makeStyles(theme => ({
  back: {
    background:
      'radial-gradient(100% 225% at 0% 0%, #DE3E3E 0%, #17115C 100%), radial-gradient(100% 225% at 100% 0%, #FF9040 0%, #FF0000 100%), linear-gradient(180deg, #CE63B7 0%, #ED6283 100%), radial-gradient(100% 120% at 75% 0%, #A74600 0%, #000000 100%), linear-gradient(310deg, #0063D8 0%, #16009A 50%)',
    backgroundBlendMode:
      'overlay, color-dodge, color-burn, color-dodge, normal',
    marginLeft: 0,
    marginRight: 0,
    color: 'white'
  },
  chip: {
    margin: '4px'
  }
}))
const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function AvailListDialog ({ nodeLib, postCal }) {
  const classes = useStyles()
  const [copySuccess, setCopySuccess] = useState(false)
  const [snackOpen, setSnackOpen] = useState(false)
  const textAreaRef = useRef(null)

  function copyToClipboard (e, val) {
    textAreaRef.current.hidden = false
    textAreaRef.current.value = val
    textAreaRef.current.select()
    document.execCommand('copy')
    e.target.focus()
    setSnackOpen(true)
    setOpen(false)
    setCopySuccess(`${val} successfully copied to clipboard!`)
    textAreaRef.current.hidden = true
  }
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSnackOpen(false)
  }

  return (
    <>
      {document.queryCommandSupported('copy') && (
        <Box
          display='flex'
          flexDirection='row-reverse'
          style={{ marginTop: '15px', marginBottom: '15px' }}
          className='avail'
        >
          <Button
            size='small'
            className={classes.back}
            onClick={handleClickOpen}
          >
            Show Available Properties
          </Button>
        </Box>
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {'Available Parameters & Functions'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            Parameters
            {paramListForNodes.map((e, i) => (
              <Chip
                onClick={ev => copyToClipboard(ev, e)}
                clickable
                color='primary'
                className={classes.chip}
                variant='outlined'
                key={i}
                label={e}
              />
            ))}
            {postCal &&
              paramListOnlyPostCal.map((e, i) => (
                <Chip
                  onClick={ev => copyToClipboard(ev, e)}
                  clickable
                  color='primary'
                  className={classes.chip}
                  variant='outlined'
                  key={i}
                  label={e}
                />
              ))}
            <br />
            Functions
            {nodeLib
              ? functionList.map((e, i) => (
                  <Chip
                    onClick={ev => copyToClipboard(ev, e)}
                    clickable
                    color='secondary'
                    className={classes.chip}
                    variant='outlined'
                    key={i}
                    label={e}
                  />
                ))
              : prePosFuntList.map((e, i) => (
                  <Chip
                    onClick={ev => copyToClipboard(ev, e)}
                    clickable
                    color='secondary'
                    className={classes.chip}
                    variant='outlined'
                    key={i}
                    label={e}
                  />
                ))}
            <br />
            Operators
            {operatorList.map((e, i) => (
              <Chip
                onClick={ev => copyToClipboard(ev, e)}
                clickable
                color='primary'
                className={classes.chip}
                variant='outlined'
                key={i}
                label={e}
              />
            ))}
            <textarea ref={textAreaRef} hidden />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        message={copySuccess}
      />
    </>
  )
}
