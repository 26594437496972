export const portConf = {
  port1: {
    id: 'port1',
    type: 'output',
    properties: {
      value: 'yes',
      linkColor: 'green'
    }
  },
  port2: {
    id: 'port2',
    type: 'output',
    properties: {
      value: 'no',
      linkColor: 'red'
    }
  },
  port3: {
    id: 'port3',
    type: 'input'
  }
}
