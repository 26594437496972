import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Context } from '../states/AuthContextProvider'
export default function ProctectedRoute ({ children, ...rest }) {
  const { state } = useContext(Context)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        state.username ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}
