import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@material-ui/core'
import React, { useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import {
  Content,
  NodeInnerCustom,
  NodeInnerCustomReverse,
  Page,
  PortCustom
} from './flowchart'
import { FlowChartWithState } from '@mrblenny/react-flow-chart'

const useStyles = makeStyles(theme => ({
  appBar: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  expressionBox: {
    margin: theme.spacing(1)
  }
}))

const TraversalDetail = ({ open, handleClose, conf, input, output, full }) => {
  const classes = useStyles()
  const [canvasKey, setCanvasKey] = useState(1)
  const [scale, setScale] = useState(0.2)
  const [isExpression, setIsExpression] = useState(false)
  const [isFull, setIsFull] = useState(false)

  const inputTable = input ? Object.keys(input) : []
  const outputTable = output ? Object.keys(output) : []
  conf.scale = scale
  full.scale = scale

  const handleZoomIn = e => {
    if (scale <= 0.9) {
      setScale(scale + 0.1)
    }
    setCanvasKey(canvasKey + 1)
  }
  const handleZoomOut = e => {
    setScale(scale - 0.1)
    setCanvasKey(canvasKey + 1)
  }

  const handleExpressionView = e => {
    setIsExpression(!isExpression)
  }
  const handleIsFullSwitch = e => {
    setIsFull(!isFull)
    setCanvasKey(canvasKey + 1)
  }

  return (
    <Dialog fullScreen onClose={handleClose} open={open}>
      <DialogTitle>
        <AppBar position='fixed' className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h6'>Traversal View</Typography>
          </Toolbar>
        </AppBar>
        <Toolbar />
      </DialogTitle>

      <DialogContent style={{ marginTop: '15px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={10}>
            <Page>
              <Content>
                <FlowChartWithState
                  key={canvasKey}
                  initialValue={isFull ? full : conf}
                  Components={{
                    NodeInner: isExpression
                      ? NodeInnerCustomReverse
                      : NodeInnerCustom,
                    Port: PortCustom
                  }}
                  config={{
                    zoom: {
                      maxScale: 0.9,
                      minScale: 0.09
                    }
                  }}
                />
              </Content>
            </Page>
          </Grid>
          <Grid item xs={12} sm={2}>
            <ButtonGroup size='small' fullWidth>
              <Button fullWidth onClick={handleZoomIn} variant='outlined'>
                zoom in
              </Button>
              <Button fullWidth onClick={handleZoomOut} variant='outlined'>
                zoom out
              </Button>
            </ButtonGroup>
            <Box
              display='flex'
              flexDirection='row-reverse'
              className={classes.expressionBox}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={isExpression}
                    onChange={handleExpressionView}
                    name='expression_view'
                    color='primary'
                  />
                }
                label='Expression View'
                labelPlacement='start'
              />
            </Box>
            <Box
              display='flex'
              flexDirection='row-reverse'
              className={classes.expressionBox}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={isFull}
                    onChange={handleIsFullSwitch}
                    name='is_full_view'
                    color='primary'
                  />
                }
                label='Full'
                labelPlacement='start'
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Input Parameters</TableCell>
                    <TableCell align='right'>Values</TableCell>
                  </TableRow>
                </TableHead>
                {!!inputTable.length && (
                  <TableBody>
                    {inputTable.map(key => (
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {key}
                        </TableCell>
                        <TableCell align='right'>
                          {JSON.stringify(input[key])}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Output Parameters</TableCell>
                    <TableCell align='right'>Values</TableCell>
                  </TableRow>
                </TableHead>
                {!!outputTable.length && (
                  <TableBody>
                    {outputTable.map(key => (
                      <TableRow>
                        <TableCell component='th' scope='row'>
                          {key}
                        </TableCell>
                        <TableCell align='right'>
                          {JSON.stringify(output[key])}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default TraversalDetail
