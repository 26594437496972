import React, { useState } from 'react'
import { Box, Button, makeStyles, TextField } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'

const useStyles = makeStyles(theme => ({
  root2: {
    flexGrow: 1,
    textAlign: 'left',
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(2)
  },
  formJSON: {
    textAlign: 'left',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  }
}))

const JsonAsFormSingle = ({ setjson, json }) => {
  const classes = useStyles()
  const [btnState, setBtnState] = useState(false)
  const [paramName, setParamName] = useState('')

  const handleOnChange = (target, e) => {
    const copiedValue = JSON.stringify(json)
    const reParsedJSON = JSON.parse(copiedValue)
    reParsedJSON[e] = target.currentTarget.value.toString()
    setjson(reParsedJSON)
  }

  const handleNewParamClick = e => {
    json[paramName] = ''
    setBtnState(false)
  }

  return (
    <>
      <Box className={classes.root2}>
        {Object.keys(json)
          .sort((a, b) => a.length - b.length)
          .map((e, i) => (
            <TextField
              onChange={target => handleOnChange(target, e)}
              className={classes.formJSON}
              key={i}
              label={e}
              defaultValue={json[e]}
              variant='outlined'
              size='small'
              InputLabelProps={{
                shrink: true
              }}
              fullWidth={e.length > 30}
            />
          ))}

        {btnState ? (
          <>
            <TextField
              id='outlined-basic'
              label='name'
              name='name'
              variant='outlined'
              fullWidth
              required
              defaultValue={paramName}
              onChange={e => setParamName(e.currentTarget.value)}
              placeholder='Algorithm Name'
              autoFocus
            />
            <Button
              onClick={e => handleNewParamClick(e)}
              variant='outlined'
              fullWidth
              color='secondary'
            >
              <CheckIcon />
            </Button>
          </>
        ) : (
          <Button
            onClick={() => setBtnState(!btnState)}
            fullWidth
            color='primary'
            variant='outlined'
          >
            add new parameter
          </Button>
        )}
      </Box>
    </>
  )
}

export default JsonAsFormSingle
