import { evaluateNode } from './nodeEval'

const childrenFinder = (parentId, cLinks) => {
  const children = []
  for (const e of Object.entries(cLinks)) {
    if (e[1].from.nodeId === parentId) {
      if (e[1].from.portId === 'port1') {
        children[0] = e[1].to.nodeId
      } else {
        children[1] = e[1].to.nodeId
      }
    }
  }
  return children
}

export const confTransformer = (subTreeConf, nodeList) => {
  let index = 0
  const nodes = []
  for (const [key, value] of Object.entries(subTreeConf.conf.nodes)) {
    const storedNodeString = nodeList.find(
      e => e.id === value.properties.custom
    )
    const nodeJSON = storedNodeString
    const children = childrenFinder(key, subTreeConf.conf.links)
    nodes.push({
      id: key,
      expression: nodeJSON.expression,
      isRoot: index === 0 ? true : false,
      isLeaf: children.length === 0 ? true : false,
      children
    })
    index++
  }
  return nodes
}

export const subtreeEval = (subTreeString, nodeList, body) => {
  const error = []
  const subTreeConf = subTreeString
  const transFormedConf = confTransformer(subTreeConf, nodeList)

  let currentNode = transFormedConf.find(e => e.isRoot === true)

  const childrenFinder = (arr, id) => arr.find(e => e.id === id)
  let result = null

  while (true) {
    const evaluatedNode = evaluateNode(currentNode.expression, body)
    let value = evaluatedNode.value
    error.push(...evaluatedNode.error)
    if (value) {
      currentNode = childrenFinder(transFormedConf, currentNode.children[0])
    } else {
      currentNode = childrenFinder(transFormedConf, currentNode.children[1])
    }

    if (currentNode.isLeaf === true) {
      result = evaluateNode(currentNode.expression, body).value
      break
    }
  }

  return { value: result, error }
}
