import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import React from 'react'

const AlgoCloneWarning = ({ onClose, selectedValue, open }) => {
  const handleClose = () => {
    onClose(selectedValue)
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
      PaperProps={{
        style: {
          backgroundColor: '#eb3b5a',
          color: 'white'
        }
      }}
    >
      <DialogTitle id='simple-dialog-title'>Important!</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: 'white' }}>
          The cloned algorithm will continue to use the original algorithm
          nodes. So any change in the nodes will impact both algorithms. Cloning
          should only be used if new branches need to be created or nodes need
          to be added to the cloned algorithm. Deleting or editing pre-existing
          nodes obtained from the original algorithm, within the new cloned
          algorithm, will impact the original algorithm as well.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default AlgoCloneWarning
